import { Box, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import theme from '../../theme';
import { FilterOption, FilterSelectProps } from './FilterSelectProps';
import useStyles from './style';

/**
 * Componente para selecionar opções para serem utilizada no filtro do
 * conteúdo da pagina
 * @param props
 * @returns
 */
const FilterSelect: React.FC<FilterSelectProps> = ({ selectedPosition = 'down', ...props }) => {
    const classes = useStyles();
    const [selectedOptions, setSelectedOptions] = useState<Array<FilterOption>>(props.initValues || []);
    const [value, setValue] = useState<FilterOption | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [direction] = useState(selectedPosition === 'down' ? 'column' : 'column-reverse');
    const [labelColor, setLabelColor] = useState(theme.palette.grey[600])
    const [options, setOptions] = useState<Array<FilterOption>>(props.options);


    /** Reordena a lista de opções */
    useEffect(() => {
        options.sort((a, b) => a.label.localeCompare(b.label));
    }, [options]);

    /** Carregos valores iniciais */
    useEffect(() => {
        if (props.initValues) {
            setSelectedOptions(props.initValues);
        }
    }, [props.initValues]);

    /** Carrega as opções */
    useEffect(() => {
        if (props.options) {
            setOptions(props.options);
        }
    }, [props.options]);

    /**
     * Renderiza as opções selecionadas no filtro
     * @returns
     */
    function renderSelectedOptions() {
        return selectedOptions.map(option => {
            return (
                <Box className={classes.selectedBox} key={option.value}>
                    {option.label}&nbsp;
                    <CloseIcon onClick={() => removeSelectedOption(option)} />
                </Box>
            )
        })
    }

    /**
     * Remove uma opção dos filtros selecionados
     * @param option
     */
    function removeSelectedOption(option: FilterOption) {
        const filtered = selectedOptions.filter(o => option !== o);
        setSelectedOptions(filtered);
        if (options.indexOf(option) === -1) {
            setOptions([...options, option]);
        }
        if (props.OnChangeFilter) {
            props.OnChangeFilter(filtered);
        }
    }

    /**
     * Seleciona um opção para o filtro selecionado
     * @param e
     * @param value
     */
    function selectOption(e: any, value: FilterOption | null | string) {
        let newOption: FilterOption | null;
        if (typeof (value) === 'string') {
            newOption = { label: value, value: options.length }
        } else {
            newOption = value;
        }
        if (newOption) {
            const selected = [...selectedOptions, newOption];
            setSelectedOptions(selected)
            const filtered = options.filter(option => option !== newOption);
            setOptions([...filtered])
            setValue(null);
            setInputValue('');
            if (props.OnChangeFilter) {
                props.OnChangeFilter(selected);
            }
        }
    }

    const optionslist = () => {
        const diff: FilterOption[] = [...options];
        selectedOptions.forEach(item => {
            const index = diff.findIndex(option => option.value === item.value);
            if (index > -1) {
                diff.splice(index, 1);
            }
        })
        return diff;
    }

    return (
        <Box className={classes.root}>
            <Box flexDirection={direction} display="-webkit-flex">
                <Autocomplete
                    onFocus={() => setLabelColor(theme.palette.primary.main)}
                    onBlur={() => setLabelColor(theme.palette.grey[500])}
                    options={optionslist()}
                    value={value}
                    getOptionLabel={(option) => option.label}
                    onChange={selectOption}
                    inputValue={inputValue}
                    freeSolo={props.freeSolo}
                    onInputChange={(e: any, value) => setInputValue(value)}
                    renderInput={(params) => <TextField {...params}
                        label={props.title}
                        variant="outlined" placeholder={props.placeholder} 
                        InputLabelProps={{ shrink: true }} />}
                />
                <Box my={1}>
                    {renderSelectedOptions()}
                </Box>
            </Box>
        </Box>
    );
}

export default FilterSelect;