/**
 * Regex to validates email
 */
const mailRegEx = RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
/**
 * Validates email
 * @param email
 * @returns true if valid
 */
export const EmailValidator = (email:string) => {
    return mailRegEx.test(email);
}