import { AppBar, Box, Button, Container, Drawer, Hidden, IconButton, Popover, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import logo from '../../assets/TelaAmazonia-V-Color.png';
import { isAuthenticated } from "../../services/auth";
import LanguageSelector, { LANG_KEY } from "../LanguageSelector";
import { HeaderProps } from "./HeaderProps";
import useStyles from './style';

/**
 * Barra de navegação do Tela Amazônia
 * @param props
 */
const Header: React.FC<HeaderProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setIsLoggedIn(isAuthenticated())
    }, [location.pathname])

    const menu = [
        {
            text: t("header.menu.blog"),
            link: "/observatorio",
        },
        {
            text: t("header.menu.contact"),
            link: "/fale-conosco"
        },
    ];

    /**
     * Logo tipo do tela amazonia
     */
    const Logo: React.FC = () => {
        return (
            <Box display="-webkit-flex" height="80px" flexGrow={1}>
                <Button component={Link} to="/" style={{ backgroundColor: "transparent" }}>
                    <img src={logo} alt="Tela Amazônia" height="80" />
                </Button>
            </Box>
        )
    }

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const aboutPopover = () => {
        
        return (
            <Popover
                id='aboutpopover'
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {sobreSubMenu()}
            </Popover>
        )
    }

    const sobreSubMenu = () => {
        const handleClickAbout = () => {
            setOpenDrawer(false);
            setAnchorEl(null);
        }
        return <>
            <Button className={classes.button} component={Link} to='/plataforma'
                onClick={handleClickAbout}
                fullWidth >
                {t("header.menu.platform")}
            </Button>
            <Button className={classes.button} component={Link} to='/equipe'
                fullWidth
                onClick={handleClickAbout}>
                {t("header.menu.team")}
            </Button>
        </>
    }

    /**
     * Renderiza os botões do menu
     * @param direction "row | column"
     * @returns
     */
    function renderMenuItems(direction: string) {

        return (
            <Box display="-webkit-flex" flexDirection={direction}>
                <Button className={classes.button}
                    component={Link} to="/guia-audiovisual"
                    onClick={() => setOpenDrawer(false)}>
                    {t("header.menu.guide")}
                </Button>
                <Button className={classes.button}
                    component={Link} to="/cadastro-usuario"
                    onClick={() => setOpenDrawer(false)}>
                    {t("header.menu.register")}
                </Button>
                { direction === 'row' ? (
                    <>
                    <Button className={classes.button}
                    aria-describedby='aboutpopover'
                    onClick={handleClick}>
                    {t("header.menu.about")}
                    </Button>
                    {aboutPopover()}
                    </>
                    ) : sobreSubMenu()}
                {menu.map((item) => (
                    <Button className={classes.button} component={Link} to={item.link}
                        onClick={() => setOpenDrawer(false)} key={item.text} >
                        {item.text}
                    </Button>
                ))}
                {
                    isLoggedIn ? (
                        <Button color="secondary" variant="contained"
                            component={Link} to="/perfil"
                            onClick={() => setOpenDrawer(false)}>
                            {t("header.menu.profile")}
                        </Button>
                    )
                        :
                        (
                            <Button color="secondary" variant="contained"
                                component={Link} to="/autenticacao-usuario"
                                onClick={() => setOpenDrawer(false)}>
                                {t("header.menu.signin")}
                            </Button>
                        )
                }
            </Box>
        );
    }

    /**
     * Renderiza a toolbar do header
     * @returns
     */
    function renderToolbar() {
        return (
            <Toolbar className={classes.toolbar} style={{ alignItems: "flex-end" }}>
                <Hidden lgUp>
                    <Box color="#9a9a9a">
                        <IconButton
                            edge="start"
                            aria-label="menu"
                            color="inherit"
                            onClick={() => setOpenDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                    </Box>
                </Hidden>
                <Logo />
                <Hidden mdDown>
                    {renderMenuItems('row')}
                </Hidden>
            </Toolbar>
        );
    }

    /**
     * Renderiza o menu lateral
     * @returns
     */
    function renderDrawer() {
        return (
            <Drawer anchor="left" open={openDrawer}
                onClose={() => setOpenDrawer(false)}>
                <Box display="-webkit-flex"
                    flexDirection="column"
                    p={2} className={classes.toolbar}
                    alignItems="center">
                    <Logo />
                    {renderMenuItems('column')}
                </Box>
            </Drawer>
        );
    }

    let language = localStorage.getItem(LANG_KEY);
    if (!language) {
        language = 'pt-BR';
        localStorage.setItem(LANG_KEY, language);
    }

    return (
        <Suspense fallback={null}>
            <AppBar position="fixed" className={classes.appBar}
                elevation={1}>
                <Container>
                    <LanguageSelector language={language} />
                    {renderToolbar()}
                </Container>
            </AppBar>
            <Hidden mdUp>
                {renderDrawer()}
            </Hidden>
            <Box className={classes.barOffset} />
        </Suspense>
    );
};

export default Header;
