import { Box, Breadcrumbs, Paper } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import CustomBreadcrumb, { Trail } from '../../components/CustomBreadcrumb';
import SkillItens from '../../components/SkillItens';
import { Professional } from '../../models';
import useStyles from './style';

const ProfileBar: React.FC<{ data: Professional }> = ({ data, ...props }) => {

    const classes = useStyles();

    const trail: Trail[] = [
        { label: 'Início', link: '/' },
        { label: 'Catálogo de Profissionais', link: '/guia-audiovisual' },
        { label: 'Profissional' }
    ];

    return (
        <Paper className={classes.paper} >
            <CustomBreadcrumb trail={trail} />
            <Box className={classes.avatar}
                style={{ backgroundImage: `url(${data.avatar})` }}>
            </Box>
            {data.person_type === 'NP' &&
                (
                    <>
                        <SkillItens title="Autodeclaração" skills={
                            data.details?.skin_tone ? [data.details?.skin_tone.name] : []} />
                        <SkillItens title="Identidade de Gênero" skills={
                            data.gender ? [data.gender.name] : []} />
                        <SkillItens title="Orientação Sexual" skills={
                            data.details?.sexual_orientation ?
                                [data.details.sexual_orientation.name] : []} />
                    </>
                )}
        </Paper >
    )
}

export default ProfileBar;