import { Box, Container, Grid, IconButton, InputAdornment, Paper, TextField } from "@material-ui/core";
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/CPagination";
import OrderIcon from "../../components/OrderIcon";
import UserCardList from "../../components/UserCardList/UserCardList";
import { Professional } from "../../models";
import { ProfessionalSearchParam, searchProfessionals } from "../../services/CatalogoService";
import theme from '../../theme';
import FilterBar from "./FilterBar";
import useStyles from './style';

const GuiaAudioVisual = (props: any) => {

    const classes = useStyles();
    const [professionals, setProfessionals] = useState<Professional[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const ITEMS_PER_PAGE = 15;
    const [totalItems, setTotalItems] = useState(0);
    const [filter, setFilter] = useState<ProfessionalSearchParam | null>(null);
    const [searchValue, setSearchValue] = useState('');
    const { t } = useTranslation();


    const search = useCallback( () => {
        window.scrollTo(0,0);
        searchProfessionals(
            page,
            {ordering: 'professionaldetails__corporate_name' , ...filter},
            (response: any) => {
                if (page === 1) {
                    setTotalItems(response.data.count);
                }
                setProfessionals(response.data.results);
                setLoading(false);
            },
            (err: any) => {
                console.error(err);
                setLoading(false);
            },
            ITEMS_PER_PAGE)
    },[page, filter]);


    /** Carrega os valores dos filtros na inicialização da pagina */
    useEffect(() => {
        const { state } = props.location;
        if (state && filter === null) {
            setFilter({ ...state, ordering: 'professionaldetails__corporate_name' });
            setSearchValue(state.search);
        } else {
            search();
        }
    }, [props.location, filter, search]);


    //list professionals
    useEffect(() => {
        setLoading(true);
        if (filter) {
            search();
        }
    }, [ filter, search]);

    // const currentItems: UserCardProps[] = [];
    const paginate = (event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    /**
     * Renderiza a barra de pesquisa de profissionais
     * @returns
     */
    function renderSearchBar() {
        return (
            <Paper className={classes.searchBar} >
                <form noValidate autoComplete='off'>
                    <TextField
                        id='input-with-icon-textfield'
                        placeholder={t("home.search.placeholder")}
                        fullWidth
                        variant="filled"
                        className={classes.textFiled}
                        value={searchValue}
                        InputProps={{
                            disableUnderline: true,
                            className: classes.input,
                            endAdornment: <InputAdornment position="end">
                                <IconButton disableRipple disableFocusRipple
                                    onClick={() => setSearchValue('')}>
                                    <ClearOutlinedIcon />
                                </IconButton>
                            </InputAdornment>,
                        }}
                        onChange={(e: any) => setSearchValue(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleChangeFilter({ ...filter, search: searchValue })
                            }
                        }}
                        onFocus={e => e.target.select()}
                    />
                </form>
            </Paper>
        )
    }

    /** Veririfaca se existe algum filtro sendo aplicado */
    const hasFilter = useCallback(() => {
        let result = false;
        if (filter) {
            result = Object.entries(filter).some(([key, value]) => {
                let found = false;
                if (key !== 'ordering' && value) {
                    if (typeof (value) === 'object') {
                        found = value.length > 0;
                    } else {
                        found = true;
                    }
                }
                return found;
            })
        }
        return result
    }, [filter])


    /**
     * Renderiza o número de profissionais encontrados e o botão de ordenação
     * @returns
     */
    function renderSearchResult() {
        return (
            <Box display="-webkit-flex" fontSize="14pt" alignItems="center">
                <Box flexGrow={1}>
                    {!loading &&
                        <>
                            {t("findprofessionals.result.find")}&nbsp;
                        <Box color={theme.palette.primary.light}
                                component="span"
                                fontWeight="bold">
                                {totalItems} {t("findprofessionals.result.results")}
                        </Box>&nbsp;
                        {
                                hasFilter() ?
                                    t("findprofessionals.result.end2") :
                                    t("findprofessionals.result.end")
                            }
                        </>
                    }
                </Box>
                <Box display="-webkit-flex" alignItems="center">
                    {t("ordenation")}:&nbsp;
                    <OrderIcon svgIconProps={{ fontSize: "large" }}
                        onChangeOrder={(order) => order === 'asc' ?
                            handleChangeFilter({ ...filter, ordering: 'professionaldetails__corporate_name' }) :
                            handleChangeFilter({ ...filter, ordering: '-professionaldetails__corporate_name' })
                        } />
                </Box>
            </Box>
        )
    }

    /**
     * Atualiza a busca de profissionais
     * @param value ProfessionalSearchParam filtros de busca
     */
    const handleChangeFilter = (value: ProfessionalSearchParam) => {
        setPage(1);
        setFilter({ ...value });
    }

    return (
        <Container>
            <Grid container>
                <Grid item xs={12} sm={4} md={3}>
                    <FilterBar onChange={handleChangeFilter} initFilter={filter} />
                </Grid>
                <Grid className={classes.searchColumn} container item xs spacing={2}>
                    <Grid item xs={12}>
                        {renderSearchBar()}
                    </Grid>
                    <Grid item xs={12}>
                        {renderSearchResult()}
                    </Grid>
                    <Grid container item spacing={2} xs={12}>
                        <UserCardList users={professionals} loading={loading} />
                    </Grid>
                    <Grid item xs={12} className={classes.paginator}>
                        <Pagination
                            currentPage={page}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={totalItems}
                            handlePagination={paginate}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default GuiaAudioVisual;
