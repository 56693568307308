import api from "./api"

/**
 * Envia uma mensagem para o fale conosco
 * @param data {name, email, subject, message}
 * @param success Callback de sucesso
 * @param failed Callback de falha
 */
export function sendMessage(data: any, success: any, failed: any) {
    
    const send = async () => {
        return await api.post('message/', data);
    }
    
    send().then((response) => {
        success(response)
    }).catch((error) => {
        failed(error)
    })
}