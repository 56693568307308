import { Box, Button, Container, Grid, Link as LinkUi, Paper, TextField } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { EnvelopeFill, GeoAltFill, TelephoneFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import CustomBreadcrumb, { Trail } from '../../components/CustomBreadcrumb';
import ErrorMessage from '../../components/ErrorMessage';
import Title from '../../components/Title';
import { sendMessage } from '../../services/ContactUsService';
import theme from '../../theme';
import useStyles from './style';

const ContactUs: FC = (props) => {

    const initFormData = {
        name: '',
        email: '',
        subject: '',
        message: ''
    }
    const classes = useStyles();
    const [formData, setFormData] = useState(initFormData);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [enableButton, setEnableButton] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const { t } = useTranslation();

    const handleChange = (e: any) => {
        const value = e.target.value;
        const data = { ...formData }
        switch (e.target.name) {
            case 'name':
                data.name = value;
                break;

            case 'email':
                data.email = value;
                break;

            case 'subject':
                data.subject = value;
                break;

            case 'message':
                data.message = value;
                break;
        }
        setFormData(data);
    }

    useEffect(() => {
        const hasEmpty = Object.entries(formData).some(([key, value]) => value === '');
        setEnableButton(!hasEmpty);
    }, [formData])


    const handleSendMessage = (e: any) => {

        setIsLoading(true);
        sendMessage(formData,
            (response: any) => {
                setIsLoading(false);
                setIsSuccess(true);
            },
            (err: any) => {
                console.error(err);
                if (err.response.status === 400) {
                    if (err.response.data.email) {
                        setErrorMsg(err.response.data.email);
                    }
                } else {
                    setErrorMsg(err.message)
                }
                setIsLoading(false);
                setIsSuccess(false);
            })
    }

    const trail: Trail[] = [
        { label: t('breadcrumb.home'), link: '/' },
        { label: t('breadcrumb.contactus') }
    ]

    return (
        <Container>
            <ErrorMessage isSuccess={isSuccess} isLoading={isLoading}
                onClose={() => setIsSuccess(null)}
                successMsg="Mensagem enviada com sucesso"
                errorMsg={`Falha ao enviar a mensagem: ${errorMsg}`}
                duration={6000} />
            <CustomBreadcrumb trail={trail} />
            <Box mt={4}>
                <Title title={t('contactus.title')} hiddeButton />
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                        <Box className={classes.contactInfo}>
                            <LinkUi href="mailto:contato@telaamazonia.com.br"
                                color="inherit" display="block">
                                <EnvelopeFill color={theme.palette.primary.main} />&nbsp; contato@telaamazonia.com.br
                            </LinkUi>
                            <LinkUi href="tel: +55 92 99327-3741" color="inherit" display="block">
                                <TelephoneFill color={theme.palette.primary.main} />&nbsp; +55 92 99327-3741
                            </LinkUi>
                            <Box display="-webkit-flex" alignItems="flex-start">
                                <GeoAltFill color={theme.palette.primary.main} />&nbsp;
                                Leão do Norte Produções<br />
                                &nbsp;&nbsp;Av. Ephigênio Salles, 1299, box 67 (Impact Hub Manaus)<br />
                                &nbsp;&nbsp;Aleixo - Manaus, AM
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Paper className={classes.paper}>
                            <TextField name="name" label={t('contactus.name')} value={formData.name}
                                onChange={handleChange} fullWidth variant="outlined" />
                            <TextField name="email" label={t('contactus.email')} value={formData.email}
                                onChange={handleChange} fullWidth variant="outlined" />
                            <TextField name="subject" label={t('contactus.subject')} value={formData.subject}
                                onChange={handleChange} fullWidth variant="outlined" />
                            <TextField name="message" label={t('contactus.message')} value={formData.message}
                                onChange={handleChange} fullWidth variant="outlined"
                                multiline rows={10} />
                            <Button variant="contained" color="primary" size="large"
                                disabled={!enableButton} onClick={handleSendMessage}>
                                Enviar
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

        </Container >
    )
}

export default ContactUs;