import { createStyles, makeStyles, Theme, Unstable_TrapFocus } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer:{
            color:"white",
            padding: "16px 16px 0px",
            lineHeight: 2,
            marginTop:'10em',
            backgroundColor: "rgb(61, 176, 159)",
            '& a.MuiTypography-displayBlock':{
                margin: "5px 0 10px"
            },
            [theme.breakpoints.down('sm')]: {
                '& .MuiBox-root-56>div':{
                    width: "100%",
                    textAlign: "center"
                },
                '& .MuiGrid-item':{
                    marginBottom: "2em"
                }
              },
        }
    })
);

export default useStyles;