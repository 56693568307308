import { Box, Button, Container, Grid, Input, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { FilterOption } from "../../components/FilterSelect";
import { fetchCities, ProfessionalSearchParam } from "../../services/CatalogoService";
import { useTranslation } from "react-i18next";
import useStyles from './style';


const Home = () => {
    const classes = useStyles();
    const [searchParams, setSearchParams] = useState<ProfessionalSearchParam>({});
    const [cityOptions] = useState<Array<FilterOption>>([]);
    const [city] = useState<FilterOption | null>(null);

    const history = useHistory();
    const { t } = useTranslation();


    /** Carrega as localidades */
    useEffect(() => {
        fetchCities(
            (data: Array<FilterOption>) => {
                cityOptions.length = 0;
                cityOptions.push(...data);
            },
            (err: any) => { console.error((err)) });
    }, [cityOptions]);

    const searchProfessionals = () => {
        history.push(`/guia-audiovisual`, searchParams);
    }

    function renderSearchSection() {
        return (
            <Box className={classes.searchSection}>
                <Box display="-webkit-flex" height="100%" justifyContent="center"
                    alignItems="center" justifyItems="center" width="100%" p={2}>
                    <Container>
                        <Box justifyContent="center" display="-webkit-flex">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box color="white" textAlign="center" p={2}>
                                        <Typography color="inherit" variant="h4">
                                            {t("home.search.title")}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={7}>
                                        <Box borderRadius={8} bgcolor="white" p={1}>
                                            <Input placeholder={t("home.search.placeholder")}
                                                fullWidth disableUnderline
                                                onChange={(e) => setSearchParams(
                                                    {
                                                        ...searchParams,
                                                        search: e.target.value
                                                    })
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md>
                                        <Box borderRadius={8} bgcolor="white" p={1}>
                                            <Autocomplete
                                                options={cityOptions}
                                                value={city}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(e, value) => setSearchParams(
                                                    {
                                                        ...searchParams,
                                                        locality: Number(value?.value)
                                                    })
                                                }
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        disableUnderline: true
                                                    }}
                                                    placeholder={t("home.search.location")}
                                                />}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item container xs={12} md alignItems="center">
                                        <Button variant="contained" color="primary"
                                            fullWidth size="large" onClick={searchProfessionals}>
                                            {t("home.search.button")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
            </Box>
        )
    }

    function renderWelcomeSection() {
        return (
            <Box className={classes.welcomeSection}>
                <Box display="-webkit-flex" height="100%" justifyContent="left"
                    alignItems="center" justifyItems="center" width="100%" pl="10%">
                    <Container>
                        <Box width="100%">
                            <Grid container>
                                <Box id="welcome-text" color="#2559a3">
                                    <Box color="inherit">
                                       {t("home.banner.welcome1")}
                                    </Box>
                                    <Box color="inherit" fontWeight="900">
                                        {t("home.banner.welcome2")}
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid container>
                                <Box color="#5c6568">
                                    <Typography color="inherit" variant="h6">
                                        <Box whiteSpace="pre">
                                            {t("home.banner.subtitle")}
                                        </Box>
                                    </Typography>
                                    <Button color="primary" variant="contained"
                                        style={{ textTransform: 'none', marginTop: '1em' }}
                                        component={Link} to="/plataforma">
                                        {t("home.banner.button")}
                                    </Button>
                                </Box>
                            </Grid>
                        </Box>
                    </Container>
                </Box>

            </Box>
        )
    }

    return (
        <Box mb={-2}>
            {renderWelcomeSection()}
            {renderSearchSection()}
        </Box>
    );
};

export default Home;
