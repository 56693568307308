import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchBar: {
            flexGrow: 1,
            marginTop: theme.spacing(6),
            borderRadius: '10px !important',
            overflow: 'hidden',
            '& input':{
                padding: '25px 20px',
                background: 'none'
            },
        },
        textFiled: {
            '& :hover': {
                backgroundColor: 'white',
            }
        },
        input: {
            backgroundColor: 'white',
            '& ::placeholder': {
                color: theme.palette.primary.main,
            },
            '& :focus, :hover': {
                backgroundColor: 'white',
            }
        },
        paper: {
            padding: theme.spacing(3),
            paddingTop: 0,
            marginRight: '3em',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        },
        clickableBox: {
            cursor: 'pointer',
        },
        formControl: {
            margin: theme.spacing(3),
        },
        paginator: {
            display: 'flex',
            justifyContent: 'center',
        },
        searchColumn:{
            height: 'fit-content',
        }
    })
);

export default useStyles;