import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import React from 'react';
import {
    Facebook, Instagram, Linkedin
} from 'react-bootstrap-icons';
import { Link as RouteLink } from 'react-router-dom';
import AmCult from '../../assets/logos/amazonas-cult-white.svg';
import AmGov from '../../assets/logos/gov-am-white.svg';
import GovFed from '../../assets/logos/gov-white.svg';
import LeaoNorte from '../../assets/logos/leao-white.svg';
import Lei from '../../assets/logos/lei-aldir-white.svg';
import MinTurismo from '../../assets/logos/min-tur-white.svg';
import SecCult from '../../assets/logos/sec-cult-white.svg';
import SecEspCult from '../../assets/logos/sec-esp-cult-white.svg';
import ItauCultural from '../../assets/logos/ic-itau-cultural-branco.png.svg';
import useStyles from './style';
import { useTranslation } from 'react-i18next';



const Footer: React.FC = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    function renderTelaAmazoniaSection() {
        return (
            <Box>
                <Typography variant="caption" display="block">
                    <b>Leão do Norte Produções</b>
                </Typography>
                <Typography variant="caption" display="block">
                    CNPJ: 19.700.356/0001-66<br />
                    Av. Ephigênio Salles, 1299, box 67 (Impact Hub Manaus)<br />
                    Aleixo - Manaus, AM
                </Typography>
            </Box>
        )
    }

    function renderAboutSection() {
        return (
            <Box>
                <Typography variant="caption" display="block"><b>{t("footer.about.title")}</b>
                    <Link color="inherit" component={RouteLink}
                        to="/plataforma" display="block">{t("footer.about.platform")}</Link>
                    <Link color="inherit" component={RouteLink}
                        to="/equipe" display="block">{t("footer.about.aboutus")}</Link>
                    <Link color="inherit" component={RouteLink}
                        to="/termos" display="block">{t("footer.about.terms")}</Link>
                    <Link color="inherit" component={RouteLink}
                        to="/politica" display="block">
                        {t("footer.about.privacy")}</Link>
                </Typography>
            </Box>
        )
    }

    function renderServicesSection() {
        return (
            <Box>
                <Typography variant="caption" display="block"><b>{t("footer.services.title")}</b>
                    <Link color="inherit" component={RouteLink}
                        to="/guia-audiovisual" display="block">{t("footer.services.findprofessionals")}</Link>
                    <Link color="inherit" component={RouteLink}
                        to="/observatorio" display="block">{t("footer.services.observatory")}</Link>
                    <Link color="inherit" component={RouteLink}
                        to="/cadastro-usuario" display="block">{t("footer.services.join")}</Link>
                </Typography>
            </Box>
        )
    }

    function renderHelp() {
        return (
            <Box>
                <Typography variant="caption" display="block">
                    <b>{t("footer.help.title")}</b>
                    <Link color="inherit" component={RouteLink}
                        to="/fale-conosco" display="block">
                        {t("footer.help.contactus")}</Link>
                    <Link href="tel: +55 92 99327-3741" color="inherit" display="block">
                        +55 92 99327-3741
                    </Link>
                    <Link href="mailto:contato@telaamazonia.com.br"
                        color="inherit" display="block">
                        contato@telaamazonia.com.br
                    </Link>
                </Typography>
            </Box>
        )
    }

    function renderSocialMedia() {
        return (
            <Box>
                <Typography variant="caption" display="block">
                    <b>{t("footer.social.title")}</b></Typography>
                <Box fontSize={24}>
                    <Link href="https://facebook.com/telaamazonia" target="_blank"
                        color="inherit">
                        <Facebook />
                    </Link>&nbsp;&nbsp;
                        <Link href="https://instagram.com/telaamazonia" target="_blank"
                        color="inherit">
                        <Instagram />
                    </Link>&nbsp;&nbsp;
                        <Link href="https://www.linkedin.com/company/tela-amazonia/" target="_blank"
                        color="inherit">
                        <Linkedin />
                    </Link>
                </Box>
            </Box>
        )
    }

    function renderInstitucional() {
        return (
            <Box display="-webkit-flex" flexWrap="wrap" justifyItems="center" justifyContent="space-between" mt={2}>
                <Box m={1}>
                    <img src={LeaoNorte} alt="Leão do Norte" height="40px" width="auto" />
                </Box>
                <Box m={1}>
                    <img src={ItauCultural} alt="ItauCultural" height="40px" width="auto" />
                </Box>
                <Box m={1}>
                    <img src={Lei} alt="Lei Aldir Blanc" height="40px" width="auto" />
                </Box>
                <Box m={1}>
                    <img src={AmCult} alt="Amazonas Cultura de Valor" height="40px" width="auto" />
                </Box>
                <Box m={1}>
                    <img src={SecCult} alt="Secretaria de Cultura e Economia Criativa" height="40px" width="auto" />
                </Box>
                <Box m={1}>
                    <img src={AmGov} alt="Governo do Amazonas" height="40px" width="auto" />
                </Box>
                <Box m={1}>
                    <img src={SecEspCult} alt="Secretaria Especial de Cultura" height="40px" width="auto" />
                </Box>
                <Box m={1}>
                    <img src={MinTurismo} alt="Ministério do Turismo" height="40px" width="auto" />
                </Box>
                <Box m={1}>
                    <img src={GovFed} alt="Governo Federal" height="40px" width="auto" />
                </Box>
            </Box>
        )
    }

    return (
        <Box className={classes.footer}>
            <Container>
                <Grid container>
                    <Grid item sm={12} md={4}>
                        {renderTelaAmazoniaSection()}
                    </Grid>
                    <Grid item sm={12} md={2}>
                        {renderAboutSection()}
                    </Grid>
                    <Grid item sm={12} md={2}>
                        {renderServicesSection()}
                    </Grid>
                    <Grid item sm={12} md={2}>
                        {renderHelp()}
                    </Grid>
                    <Grid item sm={12} md={1}>
                        {renderSocialMedia()}
                    </Grid>
                </Grid>
                <Grid item sm={12}>
                    {renderInstitucional()}
                </Grid>
            <Box borderTop={1} borderColor="white" mt={2.5} pt={1} color="white">
                <Typography align="center" variant="caption" display="block">
                    {t("footer.copyright")}
                    {t("footer.poweredby")} <b>Conatus Software</b>
                </Typography>
            </Box>
            </Container>
        </Box >
    )
}

export default Footer;