import { IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { SortAlphaDown, SortAlphaDownAlt } from 'react-bootstrap-icons';
import { OrderIconProps } from './OrderIconProps';

const OrderIcon: React.FC<OrderIconProps> = (props) => {
    const asc = (
        <SortAlphaDown/>
    );
    const dec = (
        <SortAlphaDownAlt/>
    );

    const initialOrder = props.initialOrder || 'asc';
    const [order, setOrder] = useState(initialOrder);
    const [icon, setIcon] = useState(initialOrder === 'asc' ? asc : dec)

    function handleClick() {
        const newOrder = order === 'asc' ? 'dec' : 'asc';
        setIcon( order === 'asc' ? dec : asc);
        setOrder( newOrder );
        if (props.onChangeOrder) {
            props.onChangeOrder(newOrder);
        }
    }

    return (
        <IconButton onClick={() => handleClick()}>
            {icon}
        </IconButton>
    );
}

export default OrderIcon;