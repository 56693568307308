import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { FC } from 'react';

const SkillItens: FC<{title: string, skills: string[]}> = ({title, skills, ...props}) => {

    const useStyles = makeStyles((theme) => createStyles({
        skill: {
            border: `${theme.palette.primary.light} solid 1px`,
            borderRadius: 4,
            marginRight: 4,
            marginBottom: 4,
            padding: 4,
            whiteSpace: 'nowrap',
            color: grey[700],
        }
    }));
    const classes = useStyles();

    return (
        <Box mt={4}>
            <Typography variant="h6">{title}</Typography>
            <Box display="-webkit-flex" flexWrap="wrap">
                { skills.length > 0 ?
                skills.map(
                    (item: any) => <Box className={classes.skill} key={item}>
                        {item}
                    </Box>)
                : 'Não declarado'
                }
            </Box>
        </Box>
    )
}

export default SkillItens;