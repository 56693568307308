import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CNPJInput from '../../../components/CNPJInput';
import ErrorMessage from '../../../components/ErrorMessage';
import FilterSelect, { FilterOption } from '../../../components/FilterSelect';
import Title from '../../../components/Title';
import { BusinessSize, Language, Professional } from '../../../models';
import { fetchBusinessSize, fetchLanguages, updateProfessional } from "../../../services/CatalogoService";
import useStyles from '../style';

export interface iBasicDataLP {
    name?: string;
    corporateName?: string;
    cnpj?: string;
    businessSize?: BusinessSize | number;
    representative?: string;
    languages?: Language[];
    site?: string;
}

/*
 name?: string;
    corporateName?: string;
    languages?: Array<FilterOption>;
*/

const BasicDataLP: FC<{ values: Professional, onChange?: (data: iBasicDataLP) => void; }> = ({ values, onChange }) => {
    const [businessSizeOptions, setCompanySizeOptions] = useState<FilterOption[]>([]);
    const [languagesOptions] = useState<FilterOption[]>([]);
    const [basicData, setBasicData] = useState<iBasicDataLP>({
        corporateName: values.details?.corporate_name,
        name: values.name,
        businessSize: values.details?.business_size,
        representative: values.details?.representative,
        cnpj: values.details?.cpf_cnpj,
        languages: values.details?.languages,
        site: values.details?.site,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();


    /** Carrega a lista de opções de idiomas */
    useEffect(() => {
        fetchLanguages(
            (data: Array<FilterOption>) => {
                languagesOptions.length = 0;
                languagesOptions.push(...data);
            },
            (err: any) => { console.error((err)) });
    }, [languagesOptions])

    const selectedLanguages = useMemo(getSelectLanguages, [values.details?.languages])

    function getSelectLanguages() {
        const selected: FilterOption[] = [];
        if (values.details?.languages && values.details?.languages.length > 0) {
            values.details.languages.forEach(item => {
                selected.push({
                    label: item.name,
                    value: item.id
                })
            })
        }
        return selected;
    }

    /** Carrega a lista de opções de identidade de genero */
    useEffect(() => {
        if (businessSizeOptions.length === 0) {
            fetchBusinessSize(
                (data: Array<FilterOption>) => {
                    setCompanySizeOptions([...data]);
                },
                (err: any) => { console.error((err)) });
        }
    }, [businessSizeOptions]);

    useEffect(() => {
        if (onChange) {
            onChange(basicData);
        }
    })


    const handleChangeValue = (e: any) => {
        let data = { ...basicData };
        switch (e.target.name) {
            case 'businessSize':
                const index = businessSizeOptions.findIndex((item) => {
                    return item.value === e.target.value;
                })
                if (index > -1) {
                    const size = businessSizeOptions[index];
                    data = { ...data, businessSize: { id: Number(size.value), name: size.label } };
                }
                break;
            case 'cnpj':
                const cnpj = e.target.value.replace(/[.-/ ]/g, "")
                data = { ...data, cnpj: cnpj };
                break;
            case 'nomeFantasia':
                data = { ...data, corporateName: e.target.value };
                break;
            case 'razaoSocial':
                data = { ...data, name: e.target.value };
                break
            case 'representative':
                data = { ...data, representative: e.target.value };
                break;
            case 'site':
                data = { ...data, site: e.target.value };
                break;
        }
        setBasicData(data);
    }

    const handleChangeLanguage = (values: Array<FilterOption>) => {
        setBasicData({
            ...basicData, languages: values.map(item => {
                return { id: Number(item.value), name: item.label }
            })
        })
    }

    const saveData = () => {
        if (values) {
            const pro = { ...values, avatar: undefined };
            pro.name = basicData.name;
            pro.details = {
                ...values?.details,
                corporate_name: basicData.corporateName,
                languages: basicData.languages || [],
                professional_resume: values.details?.professional_resume || '',
                subject_areas: values.details?.subject_areas || [],
                expertises: values.details?.expertises || [],
                speciality: values.details?.speciality || [],
                city: values.details?.city || { id: 0, name: '', state: null },
                phone_number: values.details?.phone_number || '',
                representative: basicData.representative,
                cpf_cnpj: basicData.cnpj,
                site: basicData.site,
                business_size: typeof (basicData.businessSize) === 'number' ?
                    { id: basicData.businessSize, name: '' } : basicData.businessSize,
            };
            updateProfessional(pro,
                (response) => {
                    setIsLoading(false);
                    setIsSuccess(true);
                },
                (e) => {
                    setIsLoading(false);
                    setIsSuccess(false);
                    console.error(e);
                });

        }

    }

    /** Fecha o Snackbar e redireciona para o perfil do usuario em caso de sucesso */
    function onFinish() {
        setIsSuccess(null);
    }

    const businessSize = () => {
        let result: any = '';
        if (basicData.businessSize) {
            if (typeof (basicData.businessSize) === 'number') {
                result = basicData.businessSize;
            } else {
                result = basicData.businessSize.id;
            };
        }
        return result;
    }

    /** Habilita os campos para edição ou salva os dados alterados */
    const handleEditMode = (value: boolean) => {
        setIsEditing(value);
        if (!value) {
            saveData();
        }
    }

    return (
        <Box>
            <Title title={t('profile.basicdata')} onChangeEditMode={handleEditMode} />
            <ErrorMessage isLoading={isLoading} onClose={onFinish} isSuccess={isSuccess} />
            <Box className={isEditing ? '' : classes.disabled}>
                <TextField
                    name="nomeFantasia"
                    label={t('profile.tradename')}
                    placeholder={t('profile.placeholder.tradename')}
                    fullWidth
                    margin="normal"
                    value={basicData.corporateName}
                    onChange={handleChangeValue}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    name="razaoSocial"
                    label={t('profile.companyname')}
                    placeholder={t('profile.palceholder.companyname')}
                    fullWidth
                    margin="normal"
                    value={basicData.name}
                    onChange={handleChangeValue}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <CNPJInput
                    name="cnpj"
                    label="CNPJ"
                    value={basicData.cnpj}
                    onChange={handleChangeValue}
                />
                <TextField
                    name="representative"
                    label={t('profile.representative')}
                    placeholder={t('profile.placeholder.representative')}
                    fullWidth
                    margin="normal"
                    value={basicData.representative}
                    onChange={handleChangeValue}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    name="site"
                    label={t('profile.website')}
                    placeholder={t('profile.placeholder.website')}
                    fullWidth
                    margin="normal"
                    value={basicData.site}
                    onChange={handleChangeValue}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <FormControl>
                    <InputLabel id="sizeLabel" shrink>
                        {t('profile.companysize')}
                    </InputLabel>
                    <Select
                        name="businessSize"
                        labelId="sizeLabel"
                        onChange={handleChangeValue}
                        value={businessSize()}
                        style={{ minWidth: 160, marginBottom:20 }}
                    >
                        {businessSizeOptions.map((item) => {
                            return <MenuItem key={item.label} value={item.value}>
                                {item.label}
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FilterSelect options={languagesOptions} initValues={selectedLanguages}
                    title={t('findprofessionals.languages')} OnChangeFilter={handleChangeLanguage} />

            </Box>
        </Box>
    )

}

export default BasicDataLP;
