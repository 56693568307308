import { Box, Breadcrumbs, createStyles, makeStyles, Theme } from "@material-ui/core"
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import React, { FC, useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom"
import { getPageBySlug } from '../services/BlogServices'
import CustomBreadcrumb from "./CustomBreadcrumb"

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        minHeight: '80vh',
    },
    pageContent: {
        '& h1, h2, h3, h4': {
            color: '#42ae9f !important'
        },
        '& h1': {
            textTransform: 'uppercase'
        },
        '& p': {
            color: '#4f4f4f !important',

        },
        '& p > span, p > span > *': {
            color: '#4f4f4f !important',
            fontSize: '1rem !important'
        },
        '& *': {
            fontFamily: 'Raleway !important',
        }
    }
}))

const SimplePage: FC<{
    slug: string,
    breadcrumbs: Array<{ link?: string, label: string }>
}> = ({ slug, breadcrumbs }) => {
    
    const classes = useStyles();
    const [pageContent, setPageContent] = useState<string>('')
    const history = useHistory();
    
    useEffect(() => {
        getPageBySlug(slug, (response: any) => {
            setPageContent(response.data.content)
        },
            (err: any) => {
                console.error(err);
                history.push('/error');
            })
    }, [])

    return (
        <Container maxWidth="lg" className={classes.root}>
            <CustomBreadcrumb trail={breadcrumbs}/>
            <Typography
                color="textSecondary"
                gutterBottom
                dangerouslySetInnerHTML={{ __html: pageContent }}
                className={classes.pageContent} />
        </Container>
    )
}
export default SimplePage;