import red from "@material-ui/core/colors/red";
// corrige problemas do modo estrito
import { unstable_createMuiStrictModeTheme } from "@material-ui/core/styles";
import { Shadows } from "@material-ui/core/styles/shadows"
import "@fontsource/raleway"
import "@fontsource/raleway/900.css"
import "@fontsource/raleway/600.css"

// A custom theme for this app
const theme = unstable_createMuiStrictModeTheme({
  palette: {
    primary: {
      light: "#48a999",
      main: "#42AE9F",
      dark: "#004c40",
      contrastText: "#fff"
    },
    secondary: {
      light: "#5eb8ff",
      main: "#2682A1",
      dark: "#005b9f",
    },
    error: {
      main: red.A400,
    },
  },
  shadows: [
    "none",
    "rgb(0 0 0 / 20%) 0px 3px 10px 0px",
    "rgb(0 0 0 / 20%) 0px 3px 10px 0px",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none"] as Shadows,
  shape: {
    borderRadius: 10
  },
  overrides: {
    MuiButton: {
      root: {
        'border-radius': '5px'
      }
    },
    MuiInputLabel: {
      root: {
        color: "rgba(0, 0, 0, 0.65)",
      },
    },
  },
  typography: {
    fontFamily: 'Raleway'
  }
  
});

export default theme;
