import { Box, Snackbar, Tab, Tabs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import VisibilityOnIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Alert } from "@material-ui/lab";
import { ChangeEvent, FormEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { EmailValidator } from "../../components/EmailValidator";
import api from "../../services/api";
import { login, logout } from "../../services/auth";
import useStyles from './styles';

interface SignupProps {
    name: string;
    email: string;
    password: string;
    account_type: string;
    person_type: string;
    terms_accepted: boolean;
    isError: {
        email: string;
        name: string;
        password: string;
        terms_accepted: string;
    };
}

const initialState: SignupProps = {
    name: '',
    email: '',
    password: '',
    account_type: 'PRO',
    person_type: 'NP',
    terms_accepted: false,
    isError: {
        email: '',
        name: '',
        password: '',
        terms_accepted: '',
    },
};

const UserRegister = (props: any) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    // estado do form
    const [errorAlert, setErrorAlert] = useState([false, '']);
    const [formData, setFormData] = useState(initialState);
    const [showPassword, setShowPassword] = useState(false);
    const { isError } = formData;

    const params = useMemo(
        getParams, [props.location]);

    /** Carrega a filtro de acordo com os parametros passados */
    function getParams() {
        const { state } = props.location;
        return state || {};
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, ...{ [name]: value } });
    };

    const handleChangeTab = (e: ChangeEvent<{}>, value: any) => {
        setFormData({ ...formData, person_type: value });
    };

    const handleCheckboxChange = (
        e: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => {
        setFormData({ ...formData, ...{ [e.target.name]: checked } });
    };

    // validação do form
    const isFormValid = ({ isError, ...rest }: SignupProps) => {
        let isValid = true;

        for (const [name, value] of Object.entries(rest)) {
            switch (name) {
                case 'name':
                    isError.name =
                        value.length < 4
                            ? 'user.form.errors.name.tooShort'
                            : '';
                    break;
                case 'email':
                    isError.email = EmailValidator(value)
                        ? ''
                        : 'user.form.errors.email.patternMismatch';
                    break;
                case 'password':
                    isError.password =
                        value.length < 8
                            ? 'user.form.errors.password.tooShort'
                            : '';
                    break;
                case 'terms_accepted':
                    isError.terms_accepted = !value
                        ? 'user.form.errors.terms.valueMissing'
                        : '';
                    break;
                default:
                    break;
            }
        }

        for (const val of Object.values(isError)) {
            if (val.length > 0) {
                isValid = false;
                break;
            }
        }

        setFormData({ ...formData, ...{ isError } });

        return isValid;
    };

    const authenticate = async (data: any) => {
        const email = data.email;
        const password = data.password;
        try {
            const res = await api.post(
                "/token/",
                { email, password },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (res.status === 200) {
                login(res.data.access);
                if (data.account_type !== 'HIRER') {
                    history.push('/cadastro-usuario/segmentacao/', data);
                } else {
                    history.push('/guia-audiovisual');
                }
            }
        } catch (err: any) {
            if (err.response) {
                setErrorAlert([true, err.response.data.detail]);
            } else {
                setErrorAlert([true, err.message]);
            }
        }
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!isFormValid(formData)) return;

        const {
            name,
            email,
            password,
            account_type,
            person_type,
            terms_accepted,
        } = formData;

        const type = params.person_type ? params.person_type : person_type;
        const account = params.account_type ? params.account_type : account_type;

        const data = {
            name,
            email,
            password,
            account_type: account,
            person_type: type,
            terms_accepted,
        };

        try {
            logout();
            const response = await api.post('/users/', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 201) {
                let user = { ...data, id: response.data.id };
                authenticate(user)
            }
        } catch (err: any) {
            if (err.response) {
                const errors: string[] = [];
                const data = err.response.data;
                if (data.email) {
                    errors.push(...data.email);
                }
                if (data.person_type) {
                    errors.push(...data.person_type);
                }
                if (data.password) {
                    errors.push(...data.password);
                }
                setErrorAlert([true, errors.join(', ')]);
            } else {
                setErrorAlert([true, err.message]);
            }
        }
    };

    //botão exibir senha
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // enumeração para o campo de tipo de pessoa
    const personTypes = [
        { value: 'NP', label: 'user.form.options.natural_person' },
        { value: 'LP', label: 'user.form.options.legal_person' },
    ];

    // personalização para o componente de aceite dos termos de uso
    const TermsLabel = (
        <>
            {t('user.form.terms_agreement') + ' '}
            <Link href='/termos' target='_blank' rel='noreferrer noopener'>
                {t('user.form.terms_link')}
            </Link>
        </>
    );

    const handleClose = () => {
        setErrorAlert([false, '']);
    };

    function handleOpen() {
        return errorAlert[0] ? true : false;
    }

    return (
        <>
            <CssBaseline />
            <Container className={classes.paper} maxWidth='xs'>
                <Snackbar open={handleOpen()}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={5000}
                    onClose={handleClose}>
                    <Alert severity="error" onClose={handleClose}>
                        {errorAlert[1]}
                    </Alert>
                </Snackbar>
                <Typography component='h1' variant='h5'>
                    {t('user.form.title').toUpperCase()}
                </Typography>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit}
                >
                    {String(params.account_type) !== 'HIRER' && (<Tabs
                        value={formData.person_type}
                        onChange={handleChangeTab}
                        indicatorColor='primary'
                        textColor='primary'
                        variant='fullWidth'
                    >
                        {personTypes.map(({ value, label }) => (
                            <Tab key={value} value={value} label={t(label)} />
                        ))}
                    </Tabs>)}

                    <Grid container spacing={2} className={classes.grid}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete='name'
                                name='name'
                                required
                                fullWidth
                                id='name'
                                label={t('user.form.name')}
                                autoFocus
                                onChange={handleChange}
                                error={isError.name.length > 0}
                                helperText={t(formData.isError.name)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id='email'
                                label={t('user.form.email')}
                                name='email'
                                autoComplete='email'
                                onChange={handleChange}
                                error={isError.email.length > 0}
                                helperText={
                                    isError.email.length > 0 &&
                                    t(formData.isError.email)
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name='password'
                                label={t('user.form.password')}
                                type={showPassword ? 'text' : 'password'}
                                id='password'
                                autoComplete='current-password'
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? (
                                                    <VisibilityOffIcon />
                                                ) : (
                                                    <VisibilityOnIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={isError.password.length > 0}
                                helperText={
                                    isError.password.length > 0 &&
                                    t(formData.isError.password)
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl
                                required
                                error={isError.terms_accepted.length > 0}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formData.terms_accepted}
                                            onChange={handleCheckboxChange}
                                            name='terms_accepted'
                                            icon={
                                                <CheckBoxOutlineBlankIcon
                                                    color={
                                                        isError.terms_accepted
                                                            .length > 0
                                                            ? 'error'
                                                            : 'inherit'
                                                    }
                                                />
                                            }
                                            color='primary'
                                        />
                                    }
                                    label={TermsLabel}
                                />
                                <FormHelperText>
                                    {t(isError.terms_accepted)}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                    >
                        {t('user.form.button_signup')}
                    </Button>
                    <Grid container justify='center' spacing={2}>
                        <Grid item>
                            <Box my={2}>
                                <Link
                                    href='/autenticacao-usuario'
                                    variant='body2'
                                >
                                    {t('user.link.have_account')}
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </form>

                <Grid container justify='center'>
                    {/* 
                    <Grid item container>
                        <LabelDivider>
                            {t('user.label.social.signup')}
                        </LabelDivider>
                    </Grid>
                    <Grid item container justify='center'>
                        <FacebookIcon fontSize='large' />
                        <TwitterIcon fontSize='large' />
                        <LinkedInIcon fontSize='large' />
                    </Grid>
                     */}
                </Grid>
            </Container>
        </>
    );
};

export default UserRegister;
