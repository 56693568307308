import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: 220,
        },
        cardContent: {
            padding: 0,
        },
        avatar: {
            width: "5rem",
            height: "5rem",
            margin: "0.5rem",
            alignSelf: "center",
        },
        name: {
            fontSize: '12pt',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center'
        },
        person_type: {
            textTransform: 'uppercase',
            fontWeight: 500,
            fontSize: '8pt',
        },
        location: {
            textTransform: 'uppercase',
            fontSize: '8pt',
            display: 'flex',
            alignItems: 'center',
            margin: '4px 0'
        },
        area: {
            textTransform: 'uppercase',
            fontSize: '6pt',
            display: 'flex',
            backgroundColor: grey[100],
            padding: 4,
            marginRight: 4,
            marginBottom: 4,
        },
        icon: {
            color: theme.palette.primary.light,
        }
    })
);

export default useStyles;