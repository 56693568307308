import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(3),
            marginBottom: theme.spacing(4),            
        },
        paginator: {
            marginTop: theme.spacing(4),
            display: 'flex',
            justifyContent: 'center',
        },
    })
);

export default useStyles;