import { Box, Button, Dialog, DialogContent, DialogTitle, Divider } from '@material-ui/core';
import React, { FC } from 'react';
import { useHistory } from 'react-router';


const AuthenticateDlg: FC<{ open: boolean, onClose: () => void }> = (
    { open, onClose }
) => {

    const history = useHistory();

    const createAccount = () => {
        history.push('/cadastro-usuario', { account_type: 'HIRER', person_type: 'NP' })
    }

    const login = () => {
        history.push('/autenticacao-usuario');
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                Para contactar um profissional é necessário estar logado na
                plataforma
            </DialogTitle>
            <DialogContent dividers>
                <Box textAlign="center">
                    <Box my={2}>
                        <Button onClick={() => createAccount()}
                            variant="contained" color="primary" size="large">
                            Criar uma Conta
                        </Button>
                    </Box>
                    <Divider />
                    <Box mt={2}>
                        <Button onClick={() => login()}
                            variant="contained" color="primary" size="large">
                            Fazer Login
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default AuthenticateDlg;