import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
            display: "flex",
            paddingTop: 10,
            top: -15,
            maxWidth: 80,
            zIndex:theme.zIndex.drawer + 2,
            borderRadius: 15,
            justifyContent: 'center',
            backgroundColor: 'white',
            marginRight: 100,
            right: "-82.5%",
            marginBottom: "-3em"
        },
    })
);

export default useStyles;