import DateFnsUtils from '@date-io/date-fns';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { FC, useEffect, useState } from 'react';
import FilterSelect, { FilterOption } from '../../../components/FilterSelect';
import { fetchGenderIdentities, fetchLanguages } from "../../../services/CatalogoService";
import brLocale from "date-fns/locale/pt-BR";
import { useTranslation } from 'react-i18next';

export interface Step1Props {
    name?: string;
    gender?: FilterOption;
    birthday?: Date | null;
    languages?: Array<FilterOption>;
}

const Step1: FC<{values: Step1Props, onChangeValues: (values: Step1Props) => void }> = (props) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(props.values.birthday || null);
    const [genderOptions, setGenderOptions] = useState<FilterOption[]>([]);
    const [languagesOptions] = useState<FilterOption[]>([]);
    const [stepData, setStepData] = useState<Step1Props>(props.values);
    const [name, setName] = useState(props.values.name || '');
    const [gender, setGender] = useState<FilterOption | null>(null);
    const [languages, setLanguages] = useState(props.values.languages || []);
    const { t } = useTranslation(); 

    /** Carrega a lista de opções de identidade de genero */
    useEffect(() => {
        if (genderOptions.length === 0) {
            fetchGenderIdentities(
                (data: Array<FilterOption>) => {
                    setGenderOptions([...data]);
                },
                (err: any) => { console.error((err)) });
        }
    }, [genderOptions]);

    /** Inicializa genero */
    useEffect(() => {
        if (props.values.gender && genderOptions.length > 0) {
            setGender(props.values.gender);
        }
    }, [genderOptions, props.values.gender]);

    /** Carrega a lista de opções de idiomas */
    useEffect(()=>{
        fetchLanguages(
            (data: Array<FilterOption>) => {
                languagesOptions.length = 0;
                languagesOptions.push(...data);
            },
            (err: any) => { console.error((err)) });
    }, [languagesOptions])

    const handleChangeGender = (e: any) => {
        const index = genderOptions.findIndex((item) => {
            return item.value === e.target.value;
        })
        if (index > -1) {
            const data = {...stepData, gender: genderOptions[index] };
            setGender(genderOptions[index]);
            setStepData(data);
            props.onChangeValues(data)
        }
    }

    const handleChangeName = (e: any) => {
        const data = {...stepData, name: e.target.value };
        setStepData(data);
        setName(e.target.value);
        props.onChangeValues(data)
    }

    const handleChangeLanguage = (values: Array<FilterOption>) => {
        const data = {...stepData, languages: values };
        setStepData(data);
        setLanguages(values);
        props.onChangeValues(data);
    }

    const handleChangeDate = (date: Date | null) => {
        const data = {...stepData, birthday: date };
        setSelectedDate(date);
        setStepData(data);
        props.onChangeValues(data);
    }

    return (
        <Box display="-webkit-flex" justifyContent="Center">
            <Box width="100%">
                <TextField
                    color="primary"
                    label={ t('register.name')}
                    placeholder={t('register.nameplaceholder')}
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={handleChangeName}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Grid container justify="space-between" alignItems="center" style={{ marginBottom: '2em' }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                        <KeyboardDatePicker
                            color="primary"
                            autoOk
                            invalidDateMessage={t('register.invaliddate')}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            label={t('register.birthdate')}
                            value={selectedDate}
                            onChange={(date) => { handleChangeDate(date) }}
                            KeyboardButtonProps={{
                                'aria-label': 'Alterar data',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <FormControl>
                        <InputLabel id="generoLable" color="primary" shrink>
                            {t('register.genderidentity')}
                        </InputLabel>
                        <Select
                            labelId="generoLabel"
                            onChange={handleChangeGender}
                            value={gender ? gender?.value : ''}
                            style={{ minWidth: 200 }}
                        >
                            {genderOptions.map((item) => {
                                return <MenuItem key={item.label} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <FilterSelect options={languagesOptions} initValues={languages}
                    title={t('register.languages')} OnChangeFilter={handleChangeLanguage}
                    placeholder={t('register.languagesplaceholder')} />
            </Box>
        </Box>
    )

}

export default Step1;
