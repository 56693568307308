import { FormControl, Input, InputLabel } from '@material-ui/core';
import React, { FC } from 'react';
import MaskedInput from 'react-text-mask';
import { CNPJInputProps, MaskedComponentProps } from './CNPJInputProps';

function maskInput(props: MaskedComponentProps) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /\d/, /\d/, '.', 
                /\d/, /\d/, /\d/, '.', 
                /\d/, /\d/, /\d/, '/',
                /\d/, /\d/, /\d/, /\d/,
                '-', /\d/, /\d/
            ]}
            placeholderChar={' '}
            showMask
        />
    )
}

const CNPJInput: FC<CNPJInputProps> = ({label='CNPJ', ...props}) => {
    return <FormControl>
        <InputLabel htmlFor="labelId">{label}</InputLabel>
        <Input
            value={props.value}
            onChange={(e: any) => {if (props.onChange) props.onChange(e)}}
            name={props.name}
            id="labelId"
            onFocus={(e) => e.target.select()}
            inputComponent={maskInput as any}
        />
    </FormControl>
}

export default CNPJInput;
