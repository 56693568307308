import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(3),
            '& > *': {
                marginTop: theme.spacing(3)
            }
        },
        contactInfo: {
            '& > *': {
                marginTop: theme.spacing(3) 
            },
            fontSize: '1rem',
        }
    })
);

export default useStyles;