import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import CNPJInput from '../../../components/CNPJInput';
import FilterSelect, { FilterOption } from '../../../components/FilterSelect';
import { fetchBusinessSize, fetchLanguages } from "../../../services/CatalogoService";

export interface Step1LPProps {
    name?: string;
    corporateName?: string;
    cnpj?: string;
    businessSize?: FilterOption;
    representative?: string;
    languages?: Array<FilterOption>;
}

const Step1: FC<{ values: Step1LPProps, onChangeValues: (values: Step1LPProps) => void }> = (props) => {
    const [stepData, setStepData] = useState<Step1LPProps>(props.values);
    const [businessSizeOptions, setCompanySizeOptions] = useState<FilterOption[]>([]);
    const [cnpj, setCNPJ] = useState(props.values.cnpj || '');
    const [name, setName] = useState(props.values.name || '');
    const [representaive, setRepresentative] = useState(props.values.representative || '');
    const [corporateName, setCorporateName] = useState(props.values.corporateName || '');
    const [businessSize, setBusinessSize] = useState<FilterOption | null>(null);
    const [languagesOptions] = useState<FilterOption[]>([]);
    const [languages, setLanguages] = useState(props.values.languages || []);

    /** Carrega a lista de opções de identidade de genero */
    useEffect(() => {
        if (businessSizeOptions.length === 0) {
            fetchBusinessSize(
                (data: Array<FilterOption>) => {
                    setCompanySizeOptions([...data]);
                },
                (err: any) => { console.error((err)) });
        }
    }, [businessSizeOptions]);

    /** Inicializa porte da empresa */
    useEffect(() => {
        if (props.values.businessSize && businessSizeOptions.length > 0) {
            setBusinessSize(props.values.businessSize);
        }
    }, [businessSizeOptions, props.values.businessSize]);

    /** Carrega a lista de opções de idiomas */
    useEffect(()=>{
        fetchLanguages(
            (data: Array<FilterOption>) => {
                languagesOptions.length = 0;
                languagesOptions.push(...data);
            },
            (err: any) => { console.error((err)) });
    }, [languagesOptions])

    const handleChangeValue = (e: any) => {
        let data = {};
        switch (e.target.name) {
            case 'businessSize':
                const index = businessSizeOptions.findIndex((item) => {
                    return item.value === e.target.value;
                })
                if (index > -1) {
                    data = { ...stepData, businessSize: businessSizeOptions[index] };
                    setBusinessSize(businessSizeOptions[index]);
                }
                break;
            case 'cnpj':
                const cnpj = e.target.value.replace(/[.-/ ]/g, "")
                data = { ...stepData, cnpj: cnpj };
                setCNPJ(e.target.value);
                break;
            case 'nomeFantasia':
                data = { ...stepData, corporateName: e.target.value };
                setCorporateName(e.target.value);
                break;
            case 'razaoSocial':
                data = { ...stepData, name: e.target.value };
                setName(e.target.value);
                break
            case 'representative':
                data = { ...stepData, representative: e.target.value };
                setRepresentative(e.target.value);
                break;
        }
        setStepData(data);
        props.onChangeValues(data)
    }

    const handleChangeLanguage = (values: Array<FilterOption>) => {
        const data = {...stepData, languages: values };
        setStepData(data);
        setLanguages(values);
        props.onChangeValues(data);
    }

    return (
        <Box display="-webkit-flex" justifyContent="Center">
            <Box width="100%">
                <TextField
                    name="nomeFantasia"
                    label="Nome Fantasia"
                    placeholder="Nome Fantasia da Empresa"
                    fullWidth
                    margin="normal"
                    value={corporateName}
                    onChange={handleChangeValue}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    name="razaoSocial"
                    label="Razão Social"
                    placeholder="Razão Social da Empresa"
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={handleChangeValue}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <CNPJInput
                    name="cnpj"
                    label="CNPJ"
                    value={cnpj}
                    onChange={handleChangeValue}
                />
                <TextField
                    name="representative"
                    label="Representante da Empresa"
                    placeholder="Nome do representante da Empresa"
                    fullWidth
                    margin="normal"
                    value={representaive}
                    onChange={handleChangeValue}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <FormControl>
                    <InputLabel id="sizeLabel">
                        Porte da Empresa
                        </InputLabel>
                    <Select
                        name="businessSize"
                        labelId="sizeLabel"
                        onChange={handleChangeValue}
                        value={businessSize ? businessSize?.value : ''}
                        style={{ minWidth: 160, marginBottom:20 }}
                    >
                        {businessSizeOptions.map((item) => {
                            return <MenuItem key={item.label} value={item.value}>
                                {item.label}
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FilterSelect options={languagesOptions} initValues={languages}
                    title="Idiomas" OnChangeFilter={handleChangeLanguage}
                    placeholder="Idiomas que os sócios falam..."/>
            </Box>
        </Box>
    )

}

export default Step1;
