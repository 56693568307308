import { Component } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { isAuthenticated } from "../services/auth";

const PrivateRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
