import { Box, Paper } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import CustomBreadcrumb, { Trail } from "../../components/CustomBreadcrumb";
import FilterExpertise from "../../components/FilterExpertise";
import FilterSelect, { FilterOption } from "../../components/FilterSelect";
import FilterShow, { FilterShowOptions } from "../../components/FilterShow";
import { Expertise } from "../../models";
import {
    fetchCities, fetchExpertises2,
    fetchGenderIdentities,
    fetchLanguages,
    fetchSexualOrientation,
    fetchSkinTones,
    fetchSpecialities, ProfessionalSearchParam
} from "../../services/CatalogoService";
import { FilterBarProps } from "./FilterBarProps";
import useStyles from './style';

const FilterBar: React.FC<FilterBarProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [cityOptions, setCityOptions] = useState<Array<FilterOption>>([]);
    const [specialityOptions, setSpecialityOptions] = useState<Array<FilterOption>>([]);
    const [genderOptions, setGenderOptions] = useState<Array<FilterOption>>([]);
    const [orientationOptions, setOrientationOptions] = useState<Array<FilterOption>>([]);
    const [skinTonesOptions, setSkinTonesOptions] = useState<Array<FilterOption>>([]);
    const [languagesOptions, setLanguagesOptions] = useState<FilterOption[]>([]);
    const [expertiseOptions, setExpertiseOptions] = useState<Array<Expertise>>([]);
    const [filter, setFilter] = useState<ProfessionalSearchParam>({});
    const [initialCities, setInitialCities] = useState<Array<FilterOption>>([]);
    const [initialSpeciality, setInitialSpeciality] = useState<Array<FilterOption>>([]);
    const [initialGender, setInitialGender] = useState<Array<FilterOption>>([]);
    const [initialOrientation, setInitialOrientation] = useState<Array<FilterOption>>([]);
    const [initialSkinTone, setInitialSkinTone] = useState<Array<FilterOption>>([]);
    const [initialLanguage, setInitialLanguage] = useState<Array<FilterOption>>([]);
    const [expertises, setExpertises] = useState<Array<Expertise>>([]);

    const FilterTitle  = {
        AREA_ATUACAO: t("findprofessionals.practicearea"),
        ESPECIALIDADE: t("findprofessionals.speciality"),
        LOCALIDADE: t("findprofessionals.location"),
        GENERO: t("findprofessionals.gender"),
        ORIENTACAO_SEXUAL: t("findprofessionals.sexualorientation"),
        COR_PELE: t("findprofessionals.selfdeclaration"),
        IDIOMA: t("findprofessionals.languages"),
    }

    /**  */
    function initialValues(values: number | number[] | undefined, list: FilterOption[]): FilterOption[] {
        const result: FilterOption[] = [];
        if (values) {
            const items = typeof (values) === 'number' ? [values] : [...values];
            items.forEach(item => {
                const index = list.findIndex(i => i.value === item);
                if (index > -1) {
                    result.push(list[index]);
                }
            })
        }
        return result;
    }

    /** Carrega as especialidades */
    useEffect(() => {
        if (specialityOptions.length === 0) {
            fetchSpecialities(
                (data: Array<FilterOption>) => {
                    const sort = data.sort((a, b) => a.label.localeCompare(b.label));
                    setSpecialityOptions([...sort]);
                },
                (err: any) => { console.error((err)) });
        }
    }, [specialityOptions]);

    /** Inicializa especialidade */
    useEffect(() => {
        if (specialityOptions.length > 0 && props?.initFilter?.speciality) {
            setInitialSpeciality(initialValues(props.initFilter.speciality, specialityOptions));
        }
    }, [specialityOptions, props?.initFilter]);

    /** Carrega generos */
    useEffect(() => {
        if (genderOptions.length === 0) {
            fetchGenderIdentities(
                (data: Array<FilterOption>) => {
                    setGenderOptions([...data]);
                },
                (err: any) => { console.error((err)) });
        };
    }, [genderOptions]);

    /** Inicializa genero */
    useEffect(() => {
        if (genderOptions.length > 0 && props?.initFilter?.gender) {
            setInitialGender(initialValues(props.initFilter.gender, genderOptions));
        }
    }, [genderOptions, props?.initFilter]);

    /** Carrega orientação sexual */
    useEffect(() => {
        if (orientationOptions.length === 0) {
            fetchSexualOrientation(
                (data: Array<FilterOption>) => {
                    setOrientationOptions([...data]);
                },
                (err: any) => { console.error((err)) });

        }
    }, [orientationOptions]);

    /** Inicializa orientação sexual */
    useEffect(() => {
        if (orientationOptions.length > 0 && props?.initFilter?.sexual_orientation) {
            setInitialOrientation(initialValues(props.initFilter.sexual_orientation, orientationOptions));
        }
    }, [orientationOptions, props?.initFilter]);

    /** Carrega cor da pele */
    useEffect(() => {
        if (skinTonesOptions.length === 0) {
            fetchSkinTones(
                (data: Array<FilterOption>) => {
                    setSkinTonesOptions([...data]);
                },
                (err: any) => { console.error((err)) });

        }
    }, [skinTonesOptions]);

    /** Inicializa cor da pele */
    useEffect(() => {
        if (skinTonesOptions.length > 0 && props?.initFilter?.skin_tone) {
            setInitialSkinTone(initialValues(props.initFilter.skin_tone, skinTonesOptions));
        }
    }, [skinTonesOptions, props?.initFilter]);

    /** Carrega as localidades */
    useEffect(() => {
        if (cityOptions.length === 0) {
            fetchCities(
                (data: Array<FilterOption>) => {
                    setCityOptions([...data]);
                },
                (err: any) => { console.error((err)) });
        }
    }, [cityOptions]);

    /** Inicializa cidade */
    useEffect(() => {
        if (cityOptions.length > 0 && props?.initFilter?.locality) {
            const index = cityOptions.findIndex(
                item => item.value === props?.initFilter?.locality);
            if (index > -1) {
                setInitialCities([cityOptions[index]]);
            }
        }
    }, [cityOptions, props?.initFilter]);

    /** Carrega os as areas de conhecimento */
    useEffect(() => {
        if (expertiseOptions.length === 0) {
            fetchExpertises2(
                (data: Array<Expertise>) => {
                    const sort = data.sort((a, b) => b.name.localeCompare(a.name));
                    setExpertiseOptions([...sort]);
                },
                (err: any) => { console.error((err)) });
        }
    }, [expertiseOptions]);

    /** Inicializa especialidade */
    useEffect(() => {
        if (expertiseOptions.length > 0 && props?.initFilter?.kf) {
            const kf = props.initFilter.kf;
            const initials = typeof (kf) === 'number' ? [kf] : [...kf];
            const expertises: Expertise[] = [];
            initials.forEach(item => {
                const index = expertiseOptions.findIndex(ex => ex.id === item);
                if (index > -1) {
                    expertises.push(expertiseOptions[index]);
                }
            })
            setExpertises([...expertises]);
        }
    }, [expertiseOptions, props?.initFilter]);

    /** Inicializa idioma */
    useEffect(() => {
        if (languagesOptions.length > 0 && props?.initFilter?.lang) {
            setInitialLanguage(initialValues(props.initFilter.lang, languagesOptions));
        }
    }, [languagesOptions, props?.initFilter]);


    /** Carrega a lista de opções de idiomas */
    useEffect(() => {
        if (languagesOptions.length === 0) {
            fetchLanguages(
                (data: Array<FilterOption>) => {
                    setLanguagesOptions([...data]);
                },
                (err: any) => { console.error((err)) });
        };
    }, [languagesOptions])

    const tipoProfissionais: Array<FilterShowOptions> = [
        { label: t("findprofessionals.professional"), value: 'NP', show: true },
        { label: t("findprofessionals.company"), value: 'LP', show: true }
    ]

    const handleOnChangeShowFilter = (values: Array<FilterShowOptions>) => {
        const showList: Array<any> = [];
        values.forEach(item => item.show ? showList.push(item.value) : '');
        filter.person_type = showList.length === 1 ? showList[0] : undefined;
        props.onChange(filter);
        setFilter(filter);
    }

    const handleChangeFilter = (filterName: string, values: Array<FilterOption>) => {
        const filterValues: Array<number> = [];
        values.forEach(item => filterValues.push(item.value as number));
        const result = filterValues.length > 0 ? filterValues : undefined;
        switch (filterName) {
            case FilterTitle.ESPECIALIDADE:
                filter.speciality = result;
                break;
            case FilterTitle.LOCALIDADE:
                filter.locality = result;
                break;
            case FilterTitle.GENERO:
                filter.gender = result;
                break;
            case FilterTitle.ORIENTACAO_SEXUAL:
                filter.sexual_orientation = result;
                break;
            case FilterTitle.COR_PELE:
                filter.skin_tone = result;
                break;
            case FilterTitle.IDIOMA:
                filter.lang = result;

        }
        props.onChange(filter);
        setFilter(filter);
    }

    function handleChangeExpertise(values: any[]) {
        const selectedList: Array<Expertise> = [];
        values.forEach(item => {
            const { group, ...expertise } = item;
            selectedList.push(expertise)
        });
        setExpertises(selectedList);
        filter.expertise = selectedList.map(item => item.id);
        props.onChange(filter);
        setFilter(filter);
    }

    const trail: Trail[] = [
        { label: t("breadcrumb.home"), link: '/' },
        { label: t("breadcrumb.professionals") }
    ]

    /**
     * Renderiza a barra de filtros
     */
    return (
        <Paper className={classes.paper}>
            <CustomBreadcrumb trail={trail} />
            <FilterShow options={tipoProfissionais}
                onChange={handleOnChangeShowFilter} />
            <Box my={4}>
                <FilterExpertise options={expertiseOptions} 
                    OnChangeFilter={handleChangeExpertise} 
                    initValues={expertises} />
            </Box>
            <Box my={4}>
                <FilterSelect title={FilterTitle.ESPECIALIDADE} options={specialityOptions}
                    placeholder={t("findprofessionals.placeholder.specialty")}
                    OnChangeFilter={
                        (values) => handleChangeFilter(FilterTitle.ESPECIALIDADE, values)
                    }
                    initValues={initialSpeciality}
                />
            </Box>
            <Box my={4}>
                <FilterSelect title={FilterTitle.LOCALIDADE} options={cityOptions}
                    placeholder={t("findprofessionals.placeholder.location")}
                    OnChangeFilter={
                        (values) => handleChangeFilter(FilterTitle.LOCALIDADE, values)
                    }
                    initValues={initialCities} />

            </Box>
            <Box my={4}>
                <FilterSelect title={FilterTitle.GENERO} options={genderOptions}
                    placeholder={t("findprofessionals.placeholder.gender")}
                    OnChangeFilter={
                        (values) => handleChangeFilter(FilterTitle.GENERO, values)
                    }
                    initValues={initialGender} />
            </Box>
            <Box my={4}>
                <FilterSelect title={FilterTitle.ORIENTACAO_SEXUAL} options={orientationOptions}
                    placeholder={t("findprofessionals.placeholder.sexualorientation")}
                    OnChangeFilter={
                        (values) => handleChangeFilter(FilterTitle.ORIENTACAO_SEXUAL, values)
                    }
                    initValues={initialOrientation} />
            </Box>
            <Box my={4}>
                <FilterSelect title={FilterTitle.COR_PELE} options={skinTonesOptions}
                    placeholder={t("findprofessionals.placeholder.selfdeclaration")}
                    OnChangeFilter={
                        (values) => handleChangeFilter(FilterTitle.COR_PELE, values)
                    }
                    initValues={initialSkinTone} />
            </Box>
            <Box my={4}>
                <FilterSelect options={languagesOptions}
                    title={FilterTitle.IDIOMA}
                    placeholder={t("findprofessionals.placeholder.language")}
                    OnChangeFilter={
                        (values) => handleChangeFilter(FilterTitle.IDIOMA, values)
                    }
                    initValues={initialLanguage} />
            </Box>
        </Paper>
    )
}

export default FilterBar;