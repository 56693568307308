import DateFnsUtils from '@date-io/date-fns';
import { Box, Grid, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as dateFns from 'date-fns';
import brLocale from "date-fns/locale/pt-BR";
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../../components/CustomSelect';
import ErrorMessage from '../../../components/ErrorMessage';
import FilterSelect, { FilterOption } from '../../../components/FilterSelect';
import Title from '../../../components/Title';
import { Language, Professional } from '../../../models';
import { fetchEducationLevels, fetchGenderIdentities, fetchLanguages, fetchSexualOrientation, fetchSkinTones, updateProfessional } from "../../../services/CatalogoService";
import useStyles from '../style';

export interface iBasicData {
    name?: string;
    corporateName?: string;
    genderId?: number | string;
    educationLevelId?: string | number | null;
    sexualOrientationId?: string | number | null;
    skinToneId?: string | number | null;
    languages?: number[];
    date_of_birth: Date | null;
}

const BasicData: FC<{ values: Professional, onChange?: (data: iBasicData) => void; }> = ({ values, onChange }) => {
    const [genderOptions, setGenderOptions] = useState<FilterOption[]>([]);
    const [sexualOrientationOptions, setSexualOrientationOptions] = useState<FilterOption[]>([]);
    const [educationLevelOptions, setEducationLevelOptions] = useState<FilterOption[]>([]);
    const [skinToneOptions, setSkinToneOptions] = useState<FilterOption[]>([]);
    const [languagesOptions] = useState<FilterOption[]>([]);
    const [basicData, setBasicData] = useState<iBasicData>({
        corporateName: values.details?.corporate_name,
        name: values.name,
        date_of_birth: values?.date_of_birth ? new Date(values?.date_of_birth + 'T00:00') : null,
        skinToneId: values.details?.skin_tone ? values.details?.skin_tone.id : '',
        genderId: values.gender ? values.gender?.id : '',
        sexualOrientationId: values.details?.sexual_orientation ? values.details?.sexual_orientation.id : '',
        educationLevelId: values.details?.education_level ? values.details?.education_level.id : '',
        languages: values.details?.languages ? values.details?.languages.map(item => Number(item.id)) : []
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();

    /** Carrega a lista de opções de idiomas */
    useEffect(() => {
        fetchLanguages(
            (data: Array<FilterOption>) => {
                languagesOptions.length = 0;
                languagesOptions.push(...data);
            },
            (err: any) => { console.error((err)) });
    }, [languagesOptions])

    const selectedLanguages = useMemo(getSelectLanguages, [values.details?.languages])

    function getSelectLanguages() {
        const selected: FilterOption[] = [];
        if (values.details?.languages && values.details?.languages.length > 0) {
            values.details.languages.forEach(item => {
                selected.push({
                    label: item.name,
                    value: item.id
                })
            })
        }
        return selected;
    }

    /** Carrega a lista de opções de dentidade de genero  */
    useEffect(() => {
        if (genderOptions.length === 0) {
            fetchGenderIdentities(
                (data: Array<FilterOption>) => {
                    setGenderOptions([...data]);
                },
                (err: any) => { console.error((err)) });
        }
    }, [genderOptions]);

    /** Carrega a lista de opções de orientação sexual*/
    useEffect(() => {
        if (sexualOrientationOptions.length === 0) {
            fetchSexualOrientation(
                (data: Array<FilterOption>) => {
                    setSexualOrientationOptions([...data]);
                },
                (err: any) => { console.error((err)) });
        }
    }, [sexualOrientationOptions]);

    /** Carrega a lista de opções de grau de instrução  */
    useEffect(() => {
        if (educationLevelOptions.length === 0) {
            fetchEducationLevels(
                (data: Array<FilterOption>) => {
                    setEducationLevelOptions([...data]);
                },
                (err: any) => { console.error((err)) });
        }
    }, [educationLevelOptions]);

    /** Carrega a lista de opções de cor da pele  */
    useEffect(() => {
        if (skinToneOptions.length === 0) {
            fetchSkinTones(
                (data: Array<FilterOption>) => {
                    setSkinToneOptions([...data]);
                },
                (err: any) => { console.error((err)) });
        }
    }, [skinToneOptions,]);

    function findOption(value: string | number, options: FilterOption[]) {
        let option = null;
        const index = options.findIndex((item) => {
            return item.value === value;
        })
        if (index > -1) {
            option = options[index]
        }
        return option;
    }

    useEffect(() => {
        if (onChange) {
            onChange(basicData);
        }
    })


    const handleOnChangeValue = (e: any) => {
        let option: FilterOption | null = null;
        let data: iBasicData = { ...basicData };
        switch (e.target.name) {
            case 'corporateName':
                data = { ...basicData, corporateName: e.target.value }
                break;
            case 'name':
                data = { ...basicData, name: e.target.value }
                break;
            case 'gender':
                option = findOption(e.target.value, genderOptions);
                data = { ...basicData, genderId: Number(option?.value) }
                break;
            case 'levels':
                option = findOption(e.target.value, educationLevelOptions);
                data = { ...basicData, educationLevelId: option?.value }
                break;
            case 'sexualOrientation':
                option = findOption(e.target.value, sexualOrientationOptions);
                data = { ...basicData, sexualOrientationId: option?.value }
                break;
            case 'skinTone':
                option = findOption(e.target.value, skinToneOptions);
                data = { ...basicData, skinToneId: option?.value }
                break;
        }
        setBasicData(data);
    }

    const handleChangeLanguage = (values: Array<FilterOption>) => {
        setBasicData({ ...basicData, languages: values.map(item => Number(item.value)) })
    }

    const handleChangeDate = (date: Date | null) => {
        setBasicData({ ...basicData, date_of_birth: date });
    }

    const saveData = () => {
        if (values) {
            const pro = { ...values, avatar: undefined };

            pro.gender = {id: Number(basicData.genderId), name: ''};
            if (basicData.date_of_birth) {
                pro.date_of_birth = pro.date_of_birth = dateFns.format(basicData.date_of_birth, 'yyyy-MM-dd');
            }
            pro.name = basicData.name;
            const langs: Language[] = []
            if (basicData.languages) {
                basicData.languages.forEach(item => langs.push({ id: item, name: '' }));
            }
            pro.details = {
                ...values?.details,
                corporate_name: basicData.corporateName,
                education_level: { id: Number(basicData.educationLevelId), name: '' },
                sexual_orientation: { id: Number(basicData.sexualOrientationId), name: '' },
                skin_tone: { id: Number(basicData.skinToneId), name: '' },
                languages: langs,
                professional_resume: values.details?.professional_resume || '',
                subject_areas: values.details?.subject_areas || [],
                expertises: values.details?.expertises || [],
                speciality: values.details?.speciality || [],
                city: values.details?.city || { id: 0, name: '', state: null },
                phone_number: values.details?.phone_number || '',
            };
            updateProfessional(pro,
                (response) => {
                    setIsLoading(false);
                    setIsSuccess(true);
                },
                (e) => {
                    setIsLoading(false);
                    setIsSuccess(false);
                    console.error(e);
                });
        }

    }

    /** Fecha o Snackbar e redireciona para o perfil do usuario em caso de sucesso */
    function onFinish() {
        setIsSuccess(null);
    }

    /** Habilita os campos para edição ou salva os dados alterados */
    const handleEditMode = (value: boolean) => {
        setIsEditing(value);
        if (!value) {
            saveData();
        }
    }

    return (
        <Box>
            <Title title={t('profile.basicdata')} onChangeEditMode={handleEditMode}/>
            <ErrorMessage isLoading={isLoading} onClose={onFinish} isSuccess={isSuccess} />
            <Box className={isEditing ? '' : classes.disabled}>
                <TextField
                    label={t('register.name')}
                    placeholder={t('register.nameplaceholder')}
                    fullWidth
                    margin="normal"
                    value={basicData.corporateName}
                    name="corporateName"
                    onChange={handleOnChangeValue}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label={t('profile.name')}
                    placeholder={t('profile.placeholder.name')}
                    fullWidth
                    margin="normal"
                    value={basicData.name}
                    name="name"
                    onChange={handleOnChangeValue}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                            <KeyboardDatePicker
                                autoOk
                                invalidDateMessage={t('register.invaliddate')}
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                label={t('register.birthdate')}
                                value={basicData.date_of_birth}
                                onChange={(date) => { handleChangeDate(date) }}
                                KeyboardButtonProps={{
                                    'aria-label': 'Alterar data',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <CustomSelect label={t('register.genderidentity')}
                            options={genderOptions}
                            name="gender"
                            onChange={handleOnChangeValue}
                            value={basicData.genderId ? basicData.genderId : ''} />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomSelect label={t('findprofessionals.sexualorientation')}
                            options={sexualOrientationOptions}
                            name="sexualOrientation"
                            onChange={handleOnChangeValue}
                            value={basicData.sexualOrientationId ? basicData.sexualOrientationId : ''} />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomSelect label={t('profile.educationallevel')}
                            options={educationLevelOptions}
                            name="levels"
                            onChange={handleOnChangeValue}
                            value={basicData.educationLevelId ? basicData.educationLevelId : ''} />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomSelect label={t('findprofessionals.selfdeclaration')}
                            options={skinToneOptions}
                            name="skinTone"
                            onChange={handleOnChangeValue}
                            value={basicData.skinToneId ? basicData.skinToneId : ''} />
                    </Grid>
                </Grid>
                <FilterSelect options={languagesOptions} initValues={selectedLanguages}
                    title={t('register.language')} OnChangeFilter={handleChangeLanguage} />
            </Box>
        </Box>
    )

}

export default BasicData;