import { Box, Snackbar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import VisibilityOnIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Alert } from "@material-ui/lab";
import { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { EmailValidator } from "../../components/EmailValidator";
import LabelDivider from "../../components/LabelDivider";
import api from "../../services/api";
import { login, logout } from "../../services/auth";
import useStyles from "./style";

interface SigninProps {
    email: string;
    password: string;
    isError: {
        email: string;
        password: string;
    };
}

const initialState: SigninProps = {
    email: "",
    password: "",
    isError: {
        email: "",
        password: "",
    },
};

export default function SignIn() {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    // estado do form
    const [errorAlert, setErrorAlert] = useState([false, '']);
    const [formData, setFormData] = useState(initialState);
    const [showPassword, setShowPassword] = useState(false);
    const { isError } = formData;

    //botão exibir senha
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // atualiza o estado do form durante alteração
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, ...{ [name]: value } });
    };

    // validação do form
    const isFormValid = ({ isError, ...rest }: SigninProps) => {
        let isValid = true;

        for (const [name, value] of Object.entries(rest)) {
            switch (name) {
                case "email":
                    isError.email = EmailValidator(value)
                        ? ""
                        : "user.form.errors.email.patternMismatch";
                    break;
                case "password":
                    isError.password =
                        value.length < 8 ? "user.form.errors.password.tooShort" : "";
                    break;
                default:
                    break;
            }
        }

        for (const val of Object.values(isError)) {
            if (val.length > 0) {
                isValid = false;
                break;
            }
        }

        setFormData({ ...formData, ...{ isError } });

        return isValid;
    };

    const handleSignIn = async (e: FormEvent) => {
        e.preventDefault();

        if (!isFormValid(formData)) return;

        const { email, password } = formData;

        try {
            logout();
            const res = await api.post(
                "/token/",
                { email, password },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (res.status === 200) {
                login(res.data.access);
                history.push("/perfil");
            }
        } catch (err: any) {
            if (err.response) {
                setErrorAlert([true, err.response.data.detail]);
            } else {
                setErrorAlert([true, err.message]);
            }
        }

    };

    const handleClose = () => {
        setErrorAlert([false, '']);
    };

    function handleOpen() {
        return errorAlert[0] ? true : false;
    }

    return (
        <>
            <CssBaseline />
            <Container component='main' maxWidth='xs' >
                <Snackbar open={handleOpen()}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={5000}
                    onClose={handleClose}>
                    <Alert severity="error" onClose={handleClose}>
                        {errorAlert[1]}
                    </Alert>
                </Snackbar>
                <Grid container className={classes.paper}>
                    <Grid item>
                        <Typography component='h1' variant='h5'>
                            {t("user.form.title_autenticacao").toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <form className={classes.form} noValidate onSubmit={handleSignIn}>
                            <TextField
                                required
                                fullWidth
                                id='email'
                                label={t("user.form.email")}
                                name='email'
                                autoComplete='email'
                                onChange={handleChange}
                                error={isError.email.length > 0}
                                helperText={
                                    isError.email.length > 0 && t(formData.isError.email)
                                }
                            />
                            <TextField
                                required
                                fullWidth
                                name='password'
                                label={t("user.form.password")}
                                type={showPassword ? "text" : "password"}
                                id='password'
                                autoComplete='current-password'
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? (
                                                    <VisibilityOffIcon />
                                                ) : (
                                                    <VisibilityOnIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={isError.password.length > 0}
                                helperText={
                                    isError.password.length > 0 && t(formData.isError.password)
                                }
                            />
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='primary'
                                className={classes.submit}
                            >
                                {t("user.form.button_send")}
                            </Button>
                            <Box my={2}>
                                <Box justifyContent="center" display="-webkit-flex" mb={2}>
                                    <Link href='https://api.telaamazonia.com/password_reset' variant='body2'>
                                        {t("user.link.forgot_password")}
                                    </Link>
                                </Box>
                                <Box justifyContent="center" display="-webkit-flex">
                                    <Link href='/cadastro-usuario' variant='body2'>
                                        {t("user.link.no_account")}
                                    </Link>
                                </Box>
                            </Box>
                        </form>
                    </Grid>
                    {/* 
                    <Grid item container>
                        <LabelDivider>
                            {t("user.label.social.signin")}
                        </LabelDivider>
                    </Grid>
                    <Grid item>
                        <FacebookIcon fontSize='large' />
                        <TwitterIcon fontSize='large' />
                        <LinkedInIcon fontSize='large' />
                    </Grid>
                     */}
                </Grid>
            </Container>
        </>
    );
}
