import { Avatar, Box, Button, Card, CardActions, CardContent, Grid, IconButton, Typography } from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import { Trash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../components/ErrorMessage';
import Title from '../../components/Title';
import { Professional } from '../../models';
import { fetchFavorites, removeFavorite } from '../../services/CatalogoService';

const Favorites: FC<{ values: Professional }> = ({ values }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [favorites, setFavorites] = useState<any[] | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (favorites === null) {
            setIsLoading(true);
            fetchFavorites(
                (favorites: any) => {
                    const results: any[] = favorites.results;
                    setFavorites([...results]);
                    setIsLoading(false);
                },
                (err: any) => {
                    setIsLoading(false);
                    console.error(err);
                })
        }
    }, [favorites]);




    const FavCard = (props: any) => {

        const removeFav = (e: any) => {
            setIsLoading(true);
            removeFavorite(props.id,
                (data: any) => {
                    setIsLoading(false);
                    setIsSuccess(true);
                    if (favorites) {
                        const index = favorites.findIndex(item => item.id === props.id);
                        if (index > -1) {
                            const newFavs = [...favorites]
                            newFavs.splice(index, 1)
                            setFavorites([...newFavs]);
                        }
                    }
                },
                (error: any) => {
                    setIsLoading(false);
                    setIsSuccess(false);
                });
        }

        return (
            <Card>
                <CardContent>
                    <Box display="-webkit-flex" alignItems="center" justifyContent="space-between">
                        <Avatar src={props.avatar} style={{ height: '60px', width: '60px' }} />&nbsp;&nbsp;
                        <Typography>{props.corporate_name}</Typography>
                        <IconButton onClick={removeFav} title="Remover Favorito"><Trash color="red" /></IconButton>
                    </Box>
                </CardContent>
            </Card>
        );
    }


    return (
        <Box>
            <ErrorMessage isLoading={isLoading} isSuccess={isSuccess}
                onClose={() => setIsSuccess(null)} />
            <Title title={t('profile.favorites')} hiddeButton />
            <Grid container spacing={2}>
                {favorites?.map(item => <Grid item sm={12} md={4}><FavCard {...item} /></Grid>)}
                {favorites?.length === 0 && <Typography>{t('profile.nofavorites')}</Typography>}
            </Grid>
        </Box>
    );
}

export default Favorites;