import { Avatar, Box, Button, Tab, Tabs, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import * as fnsDate from 'date-fns';
import ErrorMessage from '../../components/ErrorMessage';
import Title from '../../components/Title';
import { Professional } from '../../models';
import { getContacts } from '../../services/CatalogoService';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Calls: FC<{ values: Professional }> = ({ values }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [data, setData] = useState<{ forMe: any[], byMe: any[] }>({ forMe: [], byMe: [] });
    const [page, setPage] = useState(1);
    const [tab, setTab] = useState(values?.account_type === 'HIRER' ? 1 : 0);
    const [isInitialized, setIsInitialized] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (!isInitialized) {
            setIsLoading(true);
            getContacts(page,
                (contacts: any) => {
                    const calls = { ...data };
                    const results: any[] = contacts.results.reverse();
                    calls.forMe = [...results.filter(item => item.recipient.id === values.id)];
                    calls.byMe = [...results.filter(item => item.sender.id === values.id)];
                    setData({ ...calls });
                    setIsLoading(false);
                },
                (err: any) => {
                    setIsLoading(false);
                    console.error(err);
                })
            setIsInitialized(true);
        }
    }, [page, data, values.id, isInitialized]);

    function formatDate(date: string) {
        const dt = new Date(date);
        return fnsDate.format(dt, "dd-MM-yyyy à's' HH:mm");
    }

    const CallCard: FC<{
        id: any,
        name: string,
        createdDt: string,
        avatar: string,
        accountType?: string,
    }> = (
        { id, name, createdDt, avatar, accountType }
    ) => {
            return (
                <Box display="-webkit-flex" boxShadow={1} p={2} alignItems="center" borderRadius={8}
                    justifyContent="space-around">
                    <Avatar src={avatar} style={{ width: 80, height: 80 }} alt="Foto do Profissional" />
                    <Box ml={2}>
                        <Typography variant="h6" align="center">
                            {name}
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                            Data de Contato: {formatDate(createdDt)}
                        </Typography>
                    </Box>
                    <Button variant="outlined" color="primary"
                        disabled={accountType === 'HIRER'}
                        component={Link} to={`/guia-audiovisual/${id}`}
                        target="blank"
                        style={{ textTransform: 'none' }}>
                        Ver Perfil...
                    </Button>
                </Box>)
        }

    return (
        <Box>
            <ErrorMessage isLoading={isLoading} isSuccess={isSuccess}
                onClose={() => setIsSuccess(null)} />
            <Title title={t('profile.history')} hiddeButton />
            <Tabs
                value={tab}
                onChange={(e, v) => setTab(v)}
                indicatorColor='primary'
                textColor='primary'
                variant='fullWidth'
            >
                {values?.account_type !== 'HIRER' && (<Tab key={0} value={0} label={t('profile.mycalls')} />)}
                <Tab key={1} value={1} label={t('profile.requestedbyme')} />
            </Tabs>
            <Box my={4}>
                {tab === 0 && (
                    data.forMe.length > 0 ?
                        data.forMe.map(
                            item => <CallCard
                                key={item.created_at}
                                id={item.sender.id}
                                name={item.sender.name}
                                createdDt={item.created_at}
                                avatar={item.sender.avatar} 
                                accountType={item.sender.account_type}/>)
                        : <Box>{t('profile.norequested_me')}</Box>
                )}
                {tab === 1 && (
                    data.byMe.length > 0 ?
                        data.byMe.map(
                            item => <CallCard
                            key={item.created_at}
                            id={item.recipient.id}
                            name={item.recipient.name}
                            createdDt={item.created_at}
                            avatar={item.recipient.avatar} />)
                        : <Box textAlign="center">{t('profile.norequested_byme')}</Box>
                )}
            </Box>
        </Box>
    );
}

export default Calls;