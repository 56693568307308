import { Box, Button, Container, Divider, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import Pagination from "../../components/CPagination";
import Carousel from 'react-material-ui-carousel'
import CustomBreadcrumb, { Trail } from '../../components/CustomBreadcrumb';
import { searchPosts, getTags } from '../../services/BlogServices';
import theme from '../../theme';
import PostCard from './PostCard';
import useStyles from './style';
import { Link } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

const Observatory: FC = (props) => {

    const classes = useStyles();
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [posts, setPosts] = useState<any[]>([]);
    const [tags, setTags] = useState<any[]>([]);
    const [searchTags, setSearchTags] = useState<any[]>([]);
    const [selectedTag, setSelectedTag] = useState<any | null>(null);
    const [featuredPosts, setFeaturedPosts] = useState<any[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        searchPosts(searchTags, page, (response: any) => {
            const posts = [...response.data.results]
            setPosts(posts);
            const featured = posts.filter(post => post.featured_post === true);
            setFeaturedPosts([...featured])
            setTotal(response.data.count);
        }, (err: any) => { })
    }, [page, searchTags])

    useEffect(() => {
        if (tags.length === 0) {
            getTags((response: any) => {
                setTags([...response.data.results]);
            }, (err: any) => { })
        }
    }, [tags])

    const paginate = (event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const trail: Trail[] = [
        { label: t('breadcrumb.home'), link: '/' },
        { label: t('breadcrumb.observatory') }
    ];

    const searchTag = (value: any) => {
        setSelectedTag(value);
    }

    const search = (e: any) => {
        setSearchTags(selectedTag ? [selectedTag.id] : []);

    }

    const selectTag = (tag: any) => {
        setSelectedTag(tag);
        setSearchTags([tag.id]);
    }

    function paintTagBtn(tagId: number) {
        return selectedTag && selectedTag.id === tagId ?
            [grey[500], 'white'] : ['transparent', grey[500]];

    }

    return (
        <Container>
            <CustomBreadcrumb trail={trail} />
            <Grid container spacing={2}>
                <Grid item sm={12} md={8}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                            {t('observatory.title')}
                        </Typography>
                        <Typography paragraph gutterBottom align="justify">
                            {t('observatory.content1')}                            
                        </Typography>
                        <Typography paragraph gutterBottom align="justify">
                            {t('observatory.content2')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{margin: '16px 0'}}/>
                    </Grid>
                    <Grid item xs={12}>
                        {posts.map(post => <PostCard key={post.slug} {...post} />)}
                    </Grid>
                    <Grid item xs={12} className={classes.paginator}>
                        <Pagination
                            currentPage={page}
                            itemsPerPage={15}
                            totalItems={total}
                            handlePagination={paginate}
                        />
                    </Grid>
                </Grid>
                <Grid item sm={12} md={4}>
                    <Paper className={classes.paper}>
                        <Box display="-webkit-flex">
                            <Box flexGrow={1} mr={2} >
                                <Autocomplete
                                    options={tags}
                                    value={selectedTag}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, value) => searchTag(value)}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        InputProps={{ ...params.InputProps }}
                                        placeholder={t('observatory.search.placeholder')} variant="outlined"
                                    />}
                                />
                            </Box>
                            <Button variant="contained" color="primary"
                                onClick={search} disabled={!selectedTag}>{t('observatory.search.title')}</Button>
                        </Box>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Box fontSize="1.1rem" fontWeight="bold"
                            color={theme.palette.primary.main} py={1}>{t('observatory.highlights')}</Box>
                        <Carousel>
                            {featuredPosts.map(post => <Link to={`/observatorio/${post.id}`} key={post.id}>
                                <img width="100%" height="100%" src={post.featured_image} alt="Imagem do post" />
                                <Box color="white" fontWeight="bold" p={3}
                                    bgcolor="rgba(0, 0, 0, 0.75)" position="relative" bottom="75px">
                                    {post.title}
                                </Box>
                            </Link>)}
                        </Carousel>
                        {featuredPosts.length === 0 && t('observatory.noposts')}
                    </Paper>
                    <Paper className={classes.paper}>
                        <Box fontSize="1.1rem" fontWeight="bold"
                            color={theme.palette.primary.main} py={1}>TAGS</Box>
                        <Box display="-webkit-flex">
                            {tags.map(tag =>
                                <Box border={2} borderColor={grey[500]}
                                    color={paintTagBtn(tag.id)[1]} p={0.5} mr={1}
                                    onClick={() => selectTag(tag)}
                                    key={tag.id} style={{ cursor: 'pointer' }}
                                    bgcolor={paintTagBtn(tag.id)[0]}>
                                    {tag.name}
                                </Box>)}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Observatory;