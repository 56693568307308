import axios from "axios";
import { getLanguage } from "../components/LanguageSelector";
import { getToken } from "./auth";

const api = axios.create({
  baseURL: "https://api.telaamazonia.com/api/",
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  let removeToken = (config.method === 'post' && config.url === '/users/');

  if (token && !removeToken) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  const language = getLanguage();
  if (language) {
    config.headers['Accept-Language'] = language.toLowerCase();
  }
  return config;
});

export default api;
