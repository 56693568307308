import { List, ListItem, ListItemText, Paper } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { logout } from '../../services/auth';
import useStyles from './style';

const MenuBar: React.FC<{
    isHirer: boolean, 
    onChange: (option: 'editar' | 'chamados' | 'favoritos' |'conta' ) => void
}> = ({isHirer, onChange}) => {

    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const handleSair = () => {
        logout();
        history.push('/');
    }

    return (
        <Paper className={classes.menuBar} >
            <List component="nav" style={{marginTop: 32}}>
                {!isHirer && (<ListItem button>
                    <ListItemText primary={t('profile.edit_profile')} 
                    onClick={() => onChange('editar')}/>
                </ListItem>)}
                <ListItem button>
                    <ListItemText primary={t('profile.history')} 
                    onClick={() => onChange('chamados')}/>
                </ListItem>
                <ListItem button>
                    <ListItemText primary={t('profile.favorites')} 
                    onClick={() => onChange('favoritos')}/>
                </ListItem>
                <ListItem button>
                    <ListItemText primary={t('profile.settings')} 
                    onClick={() => onChange('conta')}/>
                </ListItem>
                <ListItem button>
                    <ListItemText primary={t('profile.exit')} 
                    onClick={() => handleSair()}/>
                </ListItem>
            </List>
        </Paper >
    )
}

export default MenuBar;