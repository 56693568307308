import { useTranslation } from "react-i18next";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Typography variant="h2" gutterBottom>
        404
      </Typography>
      <Typography variant="h4" gutterBottom>
        {t("404.title")}
      </Typography>
      <Typography variant="h4" gutterBottom>
        {t("404.message")}
      </Typography>
      <p>Projeto Tela Amazonas</p>
    </Container>
  );
};

export default NotFound;
