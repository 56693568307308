import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(3),
            paddingTop: 0,
            marginRight: '3em',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        },
        breadcrumbs: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.light,
            color: 'white',
            marginLeft: -theme.spacing(3),
            marginBottom: theme.spacing(3),
            fontSize: 'small',
            width:'fit-content',
            borderRadius: '5px',
            '& a': {
                textDecoration: 'none', 
                color: 'white'
            },
            minHeight: '100%',
            '& ol':{
                whiteSpace: 'nowrap',
                flexFlow: 'nowrap'
            },
        },
        avatar: {
            width: '15vw',
            height: '15vw',
            maxWidth: 204.9,
            maxHeight: 204.9,
            borderRadius: '50%',
            background: 'gray no-repeat center/cover'
        },
        skill: {
            border: `${theme.palette.primary.light} solid 1px`,
            borderRadius: 4,
            marginRight: 4,
            marginBottom: 4,
            padding: 4,
            whiteSpace: 'nowrap',
            color: grey[700],
        },
        profile: {
            padding: theme.spacing(8),
        },
        resume: {
            whiteSpace: 'pre-wrap',
        }
    })
);

export default useStyles;