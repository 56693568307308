import { Box, FormControlLabel, Switch } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterShowOptions, FilterShowProps } from './FilterShowProps';
import useStyles from './style';

const FilterShow: React.FC<FilterShowProps> = (props) => {

    const [options, setOptions] = useState<Array<FilterShowOptions>>(props.options);
    const { t } = useTranslation(); 

    function handleClick(index: number) {
        const newValues: Array<FilterShowOptions> = [...options];
        newValues[index].show = !newValues[index].show;
        if (newValues.every(item => item.show === false)) {
            if (index === 0) {
                newValues[index + 1].show = true;
            } else {
                newValues[index - 1].show = true;
            }
        };
        if (props.onChange) {
            props.onChange(newValues);
        }
        setOptions(newValues);
    }

    useEffect( () => {
        setOptions([...props.options]);
    }, [props.options])

    function renderOptions() {
        return options.map(
            (option, index) =>
                <FormControlLabel key={option.label}
                    control={<Switch checked={option.show}
                    onChange={() => handleClick(index)} size="small" />}
                    label={option.label}
                />
        )
    }

    const classes = useStyles();
    return (
        <Box>
            <Box className={classes.title}>{t("filtershow.title")}</Box>
            { renderOptions()}
        </Box>
    )
};

export default FilterShow;