import { Box } from '@material-ui/core'
import React, { FC } from 'react'

const Fieldset: FC<{disabled: boolean}> = ({disabled, ...props}) => {

    return (
        <Box style={disabled ? {pointerEvents: 'none', opacity: '0.4'} : undefined}>
            {props.children}
        </Box>
    )
}

export default Fieldset;