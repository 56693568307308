import { Box, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Expertise } from '../../models';
import theme from '../../theme';
import { FilterExpertiseProps } from './FilterExpertiseProps';
import useStyles from './style';

/**
 * Componente para selecionar opções para serem utilizada no filtro do
 * conteúdo da pagina
 * @param props
 * @returns
 */
const FilterExpertise: React.FC<FilterExpertiseProps> = ({ selectedPosition = 'down', ...props }) => {
    const classes = useStyles();
    const [selectedOptions, setSelectedOptions] = useState<Array<Expertise>>(props.initValues || []);
    const [value, setValue] = useState<Expertise | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [direction] = useState(selectedPosition === 'down' ? 'column' : 'column-reverse');
    const [labelColor, setLabelColor] = useState(theme.palette.grey[600])
    const [options, setOptions] = useState<Array<Expertise>>(props.options);
    const { t } = useTranslation();


    function sortAreas(a: any, b: any) {
        let result = a.knownledge_field.name.localeCompare(b.knownledge_field.name);
        if (a.knownledge_field.name !== b.knownledge_field.name) {
            if (a.knownledge_field.name.indexOf('Serviços') > -1) {
                result = 1;
            }
            if (b.knownledge_field.name.indexOf('Serviços') > -1) {
                result = -1;
            }
        } else {
            result = a.name.localeCompare(b.name);
        }
        return result;
    }

    /** Reordena a lista de opções */
    useEffect(() => {
        options.sort(sortAreas);
    }, [options]);

    /** Carregos valores iniciais */
    useEffect(() => {
        if (props.initValues) {
            setSelectedOptions(props.initValues);
        }
    }, [props.initValues]);

    /** Carrega as opções */
    useEffect(() => {
        if (props.options) {
            setOptions(props.options);
        }
    }, [props.options]);

    /**
     * Renderiza as opções selecionadas no filtro
     * @returns
     */
    function renderSelectedOptions() {
        return selectedOptions.map(option => {
            return (
                <Box className={classes.selectedBox} key={option.id}>
                    {option.name}&nbsp;
                    <CloseIcon onClick={() => removeSelectedOption(option)} />
                </Box>
            )
        })
    }

    /**
     * Remove uma opção dos filtros selecionados
     * @param option
     */
    function removeSelectedOption(option: Expertise) {
        const filtered = selectedOptions.filter(o => option !== o);
        setSelectedOptions(filtered);
        if (options.findIndex(item => item.id === option.id) === -1) {
            setOptions([...options, option]);
        }
        if (props.OnChangeFilter) {
            props.OnChangeFilter(filtered);
        }
    }

    /**
     * Seleciona um opção para o filtro selecionado
     * @param e
     * @param value
     */
    function selectOption(e: any, value: Expertise | null ) {
        if (value) {
            const selected = [...selectedOptions, value];
            setSelectedOptions(selected)
            const filtered = options.filter(option => option !== value);
            setOptions([...filtered])
            setValue(null);
            setInputValue('');
            if (props.OnChangeFilter) {
                props.OnChangeFilter(selected);
            }
        }
    }

    const optionslist = () => {
        const diff: Expertise[] = [...options];
        selectedOptions.forEach(item => {
            const index = diff.findIndex(option => option.id === item.id);
            if (index > -1) {
                diff.splice(index, 1);
            }
        })
        return diff;
    }

    return (
        <Box className={classes.root}>
            <Box flexDirection={direction} display="-webkit-flex">
                <Autocomplete
                    onFocus={() => setLabelColor(theme.palette.primary.main)}
                    onBlur={() => setLabelColor(theme.palette.grey[500])}
                    options={optionslist()}
                    value={value}
                    groupBy={(option) => option.knownledge_field.name}
                    getOptionSelected={(o: Expertise, v: Expertise) => { return o.id === v.id }}
                    getOptionLabel={(option) => option.name}
                    onChange={selectOption}
                    inputValue={inputValue}
                    onInputChange={(e: any, value) => setInputValue(value)}
                    renderInput={(params) => <TextField {...params} label={t("expertise.title")}
                    placeholder={t("expertise.placeholder")}
                    variant="outlined" InputLabelProps={{
                        shrink: true,
                      }} />}
                />
                <Box my={1}>
                    {renderSelectedOptions()}
                </Box>
            </Box>
        </Box>
    );
}

export default FilterExpertise;
