import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuBar: {
            padding: theme.spacing(3),
            marginRight: '3em',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        },
        avatar: {
            width: 160,
            height: 160,
            minWidth: 160,
            minHeight: 160,
            maxWidth: 160,
            maxHeight: 160,
            borderRadius: '50%',
            background: 'gray no-repeat center',
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
        },
        skill: {
            border: `${theme.palette.primary.light} solid 1px`,
            borderRadius: 4,
            marginRight: 4,
            marginBottom: 4,
            padding: 4,
            whiteSpace: 'nowrap',
            color: grey[700],
        },
        content: {
            marginTop: theme.spacing(8),
            padding: theme.spacing(4),
        },
        disabled: {
            pointerEvents: 'none',
            opacity: '0.6'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    })
);

export default useStyles;