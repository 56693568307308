import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { AddCircleOutlined, Close as CloseIcon } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../../components/ErrorMessage';
import { FilterOption } from '../../../components/FilterSelect';
import PhoneInput from '../../../components/PhoneInput';
import Title from '../../../components/Title';
import { City, Professional, SocialNetwork, State } from '../../../models';
import { fetchCitiesByState, fetchSocialNetwork, fetchStates, updateProfessional } from '../../../services/CatalogoService';
import useStyles from '../style';

export interface iSocialData {
    resume: string;
    city: City | null;
    phone: string;
    socialNetworks: SocialNetwork[];
}

/**
 * Edição dos dados sociais do perfil do usuario
 * @returns
 */
const SocialData: FC<{ values: Professional, onChange?: (values: iSocialData) => void }> = ({ values, onChange }) => {
    const [resume, setResume] = useState(values.details?.professional_resume || '');
    const [fone, setFone] = useState(values.details?.phone_number || '');
    const [city, setCity] = useState<string | number>(values.details?.city ? values.details.city.id : '');
    const [state, setState] = useState<string>(
        values.details?.city && values.details?.city.state ? values.details?.city.state?.acronym : '');
    const [data, setData] = useState<iSocialData>({
        resume: values.details?.professional_resume || '',
        city: values.details?.city || null,
        phone: values.details?.phone_number || '',
        socialNetworks: values.details?.social_networks || []
    });
    const [cityOptions, setCityOptions] = useState<Array<FilterOption>>([]);
    const [stateOptions] = useState<Array<State>>([]);
    const [socialNetworkOptions] = useState<Array<FilterOption>>([]);
    const [socialNetwork, setSocialNetwork] = useState<FilterOption | null>(null);
    const [username, setUsername] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        if (socialNetworkOptions.length === 0) {
            fetchSocialNetwork(
                (socialOptions: Array<FilterOption>) => {
                    socialNetworkOptions.push(...socialOptions);
                },
                (err: any) => { console.error((err)) });
        }
    }, [socialNetworkOptions]);

    useEffect(() => {
        fetchStates(
            (data: Array<State>) => {
                stateOptions.length = 0;
                stateOptions.push(...data);
            },
            (err: any) => { console.error((err)) });
    }, [stateOptions]);

    /** Carrega as cidades */
    useEffect(() => {
        if (state) {
            fetchCitiesByState(state,
                (data: Array<FilterOption>) => {
                    setCityOptions([...data]);
                },
                (err: any) => { console.error((err)) });
        }
    }, [state]);

    const handleChange = (e: any) => {
        let formData: iSocialData = { ...data };
        let value: any = '';
        let index = -1;
        switch (e.target.name) {
            case 'resume':
                value = e.target.value;
                setResume(value);
                formData = { ...data, resume: value };
                break;
            case 'fone':
                value = e.target.value as string;
                value = value.replace(/["' ()-]/g, "")
                setFone(value);
                formData = { ...data, phone: value };
                break;
            case 'state':
                value = e.target.value as string;
                setState(value);
                break;
            case 'city':
                index = cityOptions.findIndex(item => item.value === e.target.value);
                if (index > -1) {
                    value = cityOptions[index];
                    setCity(value.id);
                    formData = { ...data, city: value };
                }
                break;
            case 'socialNetwork':
                value = e.target.value;
                index = socialNetworkOptions.findIndex(item => item.value === value);
                if (index > -1) {
                    setSocialNetwork(socialNetworkOptions[index]);
                }
                break;
            case 'username':
                value = e.target.value;
                setUsername(value);
                break;
            default:
                break;
        }
        setData({ ...formData });
        if (onChange) {
            onChange(formData);
        }
    }

    const addSocialNetwork = (e: any) => {
        if (socialNetwork && username !== '') {
            const newItem = {
                social_media_id: Number(socialNetwork.value),
                social_media_name: socialNetwork.label,
                username: username
            }
            const newSelecteds = [...data.socialNetworks, newItem];
            setData({ ...data, socialNetworks: newSelecteds });
            setSocialNetwork(null);
            setUsername('');
        }
    }

    const removeSocialNetwork = (socialNet: SocialNetwork) => {
        const filtered = data.socialNetworks.filter(
            item => item.social_media_id !== socialNet.social_media_id
        );
        setData({ ...data, socialNetworks: [...filtered] });
    }

    const saveData = () => {
        if (values) {
            const pro = { ...values, avatar: undefined };
            pro.details = {
                ...values?.details,
                languages: values.details?.languages || [],
                subject_areas: values.details?.subject_areas || [],
                expertises: values.details?.expertises || [],
                speciality: values.details?.speciality || [],
                professional_resume: data.resume || '',
                city: data.city || { id: 0, name: '', state: null },
                phone_number: data.phone || '',
                social_networks: data.socialNetworks
            };
            updateProfessional(pro,
                (response) => {
                    setIsLoading(false);
                    setIsSuccess(true);
                },
                (e) => {
                    setIsLoading(false);
                    setIsSuccess(false);
                    console.error(e);
                });
        }
    }

    /** Fecha o Snackbar e redireciona para o perfil do usuario em caso de sucesso */
    function onFinish() {
        setIsSuccess(null);
    }

    function getSocialNetworkOptions() {
        const options: FilterOption[] = [...socialNetworkOptions];
        data.socialNetworks.forEach(item => {
            const index = options.findIndex(o => item.social_media_id === o.value);
            if (index > -1) {
                options.splice(index, 1);
            }
        });
        return options;
    }

    /** Habilita os campos para edição ou salva os dados alterados */
    const handleEditMode = (value: boolean) => {
        setIsEditing(value);
        if (!value) {
            saveData();
        }
    }

    return (
        <Box>
            <Title title={t('profile.social_title')} onChangeEditMode={handleEditMode} />
            <ErrorMessage isLoading={isLoading} onClose={onFinish} isSuccess={isSuccess} />
            <Box className={isEditing ? '' : classes.disabled}>
                <TextField
                    label={t('register.biography')}
                    placeholder={t('register.placeholder.biography')}
                    fullWidth
                    multiline
                    rows={10}
                    value={resume}
                    name="resume"
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Grid container justify="space-between" alignItems="center">
                    <FormControl>
                        <InputLabel id="EstadoLable" shrink>
                        {t('register.state')}
                    </InputLabel>
                        <Select
                            labelId="EstadoLable"
                            name="state"
                            value={stateOptions.length > 0 ? state : ''}
                            onChange={handleChange}
                            style={{ minWidth: 120 }}
                        >
                            {stateOptions.map(item => {
                                return <MenuItem key={item.acronym} value={item.acronym}>
                                    {item.name}
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="cidadeLable" shrink>
                        {t('register.city')}
                    </InputLabel>
                        <Select
                            labelId="cidadeLable"
                            name="city"
                            value={cityOptions.length > 0 ? city : ''}
                            onChange={handleChange}
                            style={{ minWidth: 120 }}
                        >
                            {cityOptions.map(item => {
                                return <MenuItem key={item.label} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <PhoneInput value={fone} onChange={handleChange} name="fone" />
                </Grid>
                <Box>

                </Box>
                <Box display="-webkit-flex" my={2}>
                    {data.socialNetworks.map(item =>
                        <Box p={1.5} mr={2} bgcolor="lightgray" borderRadius={8}
                            key={item.social_media_name}>
                            {item.social_media_name}: {item.username}&nbsp;&nbsp;
                        <IconButton size="small" onClick={() => removeSocialNetwork(item)}>
                                <CloseIcon />
                            </IconButton>
                        </Box>)}
                </Box>
                <Box component="form" display="-webkit-flex">
                    <Box>
                        <FormControl fullWidth margin="normal">
                            <InputLabel shrink>
                            {t('profile.social_network')}
                    </InputLabel>
                            <Select label={t('profile.social_network')}
                                name="socialNetwork"
                                onChange={handleChange}
                                value={socialNetwork ? socialNetwork.value : ''}
                                style={{ minWidth: 120 }}>
                                {getSocialNetworkOptions().map(item =>
                                    <MenuItem value={item.value} key={item.value}>
                                        {item.label}
                                    </MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box flexGrow={1} display="-webkit-flex">
                        <TextField id="standard-secondary"
                            label={t('profile.palceholder.username')} color="primary"
                            margin="normal"
                            name="username"
                            value={username}
                            onChange={handleChange} />
                        <IconButton color="primary" onClick={addSocialNetwork}>
                            <AddCircleOutlined />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default SocialData;