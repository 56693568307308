import { Box, Container, Grid, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RegisterStepper from './RegisterStepper';

const RegisterForm: React.FC = (props: any) => {

    const params = useMemo(getParams, [props.location]);

    /** Carrega a filtro de acordo com os parametros passados */
    function getParams() {
        const { state } = props.location;
        return state || {};
    }

    const { t } = useTranslation();

    return (
        <Container>
            <Box display="-webkit-flex" justifyContent="center">
                <Box width="60%">
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Box fontSize="h4.fontSize" color="#3db09f" textAlign="center"
                                my={2}>
                                {t('register.hello')} {params.name}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography paragraph align="center">
                                <Box whiteSpace="pre-line">{t('register.welcome')}</Box>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <RegisterStepper user={params} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export default RegisterForm;
