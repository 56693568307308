import api from "./api"

/**
 * Retorna o conteúdo da página solicitado pelo slug informado
 *
 * @param slug String formatada para urls que corresponde a uma página do backend
 * @param success Callback de sucesso da requisição
 * @param failed Callback de falha da requisição
 * @returns
 */
export function getPageBySlug(slug: string, success: any, failed: any) {
    const fetch = async () => {
        return await api.get(`/pages/${slug}/`)
    }
    const response = fetch()
    return response.then(success).catch((e) => failed(e))
}

/**
 * Retorna os posts do blog
 *
 * @param success Callback de sucesso da requisição
 * @param failed Callback de falha da requisição
 * @returns
 */
export function searchPosts(tags: number[], page: number, success: any, failed: any, pageSize: number = 15) {
    let search = '';
    if (tags.length > 0) {
        search = `&tags=${tags}`;
    }
    const fetch = async () => {
        return await api.get(`/posts/?page=${page}&page_size=${pageSize}${search}`)
    }
    const response = fetch()
    return response.then(success).catch((e) => failed(e))
}

/**
 * Retorna os posts do blog
 *
 * @param success Callback de sucesso da requisição
 * @param failed Callback de falha da requisição
 * @returns
 */
export function getPost(id: number, success: any, failed: any) {
    const fetch = async () => {
        return await api.get(`/posts/${id}/`)
    }
    const response = fetch()
    return response.then(success).catch((e) => failed(e))
}

/**
 * Retorna as tags do observatório
 *
 * @param success Callback de sucesso da requisição
 * @param failed Callback de falha da requisição
 * @returns
 */
export function getTags(success: any, failed: any) {
    const fetch = async () => {
        return await api.get('/tags/');
    }
    const response = fetch()
    return response.then(success).catch((e) => failed(e))
}