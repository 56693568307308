import jwtDecode from "jwt-decode";

export const TOKEN_KEY = "TAToken";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = (token: string) => {
	localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
	localStorage.removeItem(TOKEN_KEY);
};

/**
 * Retorna o user_id do usuario logado
 * @returns id ou ''
 */
export const getUserId = () => {
	const token = getToken();
	let jwt: any = '';
	if (token) {
		jwt = jwtDecode(token);
	} 
	return jwt['user_id'];
}
