import { Box } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Routes from "../../routes";
import Footer from "../Footer";
import Header from "../Header";
import i18next from 'i18next';
import { LANG_KEY } from "../LanguageSelector";

function App() {

    i18next.init({
        lng:  localStorage.getItem(LANG_KEY) || 'pt',
    });

    function ScrollToTop() {
        const { pathname } = useLocation();

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);

        return null;
    }

    return (
        <Suspense fallback={null}>
            <CssBaseline />
            <Router>
                <Header title='Tela Amazonas' />
                <ScrollToTop />
                <Routes />
                <Box component='main' mb={2} />
                <Footer />
            </Router>
        </Suspense>
    );
}

export default App;
