import { Box, Button, IconButton, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { CameraFill, Trash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../../components/ErrorMessage';
import Title from '../../../components/Title';
import { Professional } from '../../../models';
import { changeAvatar } from '../../../services/CatalogoService';
import useStyles from '../style';
import BasicData from './BasicData';
import BasicDataLP from './BasicDataLP';
import SocialData from './SocialData';
import SpecificData from './SpecificData';

interface RequestResult {
    isSuccess: boolean | null,
    errorMsg?: string,
    successMsg?: string
}

const EditProfile: FC<{ values: Professional }> = ({ values }) => {
    const classes = useStyles();
    const [avatar, setAvatar] = useState<{ file: any, url: string } | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [requestResult, setRequestResult] = useState<RequestResult>({ isSuccess: null });
    const { t } = useTranslation();

    /** Seleciona a imagem do avatar */
    const handleAvatarSelection = (e: any) => {
        const avatar = e.target.files[0];
        const url = URL.createObjectURL(avatar);
        setAvatar({ file: avatar, url: url });
    }

    /**
     * Troca o avatar do usuário
     * @param e
     */
    const handleChangeAvatar = (e: any) => {
        setIsLoading(true);
        changeAvatar(values.id, avatar,
            (respose) => {
                setIsLoading(false);
                setRequestResult({ isSuccess: true, successMsg: 'Imagem do perfil alterada com sucesso!' });
            },
            (err) => {
                setIsLoading(false);
                setRequestResult({ isSuccess: false, errorMsg: 'Ocorreu um erro ao alterar a imagem do perfil.' });
                console.error(err);
            })
    }

    const handleCloseMessage = (e: any) => {
        setRequestResult({ ...requestResult, isSuccess: null });
    };

    const removeAvatar = (e:any) => {
        setAvatar({ file: '', url: 'empty' });
    }

    return (
        <Box>
            <ErrorMessage isLoading={isLoading} isSuccess={requestResult.isSuccess}
                errorMsg={requestResult.errorMsg} successMsg={requestResult.successMsg}
                onClose={handleCloseMessage} />
            <Title title={t('profile.photo')} hiddeButton={true} />
            <Box display="-webkit-flex" alignItems="flex-end">
                <Box className={classes.avatar} flexGrow={1} mr={2}
                    style={{ backgroundImage: avatar?.url ? `url(${avatar?.url})` : `url(${values?.avatar})` }}>
                    <Box bgcolor="rgb(245 245 245)" color="darkred" title={t('profile.removephoto')}
                        borderRadius="50%" top="10px" position="relative" mr={1}>
                        <IconButton color="inherit" aria-label={t('profile.removephoto')} component="span" 
                            onClick={removeAvatar} size="small">
                            <Trash />
                        </IconButton>
                    </Box>
                    <Box bgcolor="rgb(245 245 245)" borderRadius="50%" top="10px" 
                        position="relative" title={t('profile.change_photo')}>
                        <input type="file" onChange={handleAvatarSelection} accept="image/*"
                            id="contained-button-file" style={{ display: 'none' }} />
                        <label htmlFor="contained-button-file">
                            <IconButton color="primary" aria-label="enviar foto" 
                                component="span" size="small">
                                <CameraFill />
                            </IconButton>
                        </label>
                    </Box>
                </Box>
                <Box>
                    <Typography variant="body2" paragraph>
                        {t('profile.photo_warning')}<br />
                        {t('profile.photo_size')}
                    </Typography>

                    <Button variant="contained" color="primary" size="small" disabled={!avatar}
                        onClick={handleChangeAvatar}>
                        {t('profile.save_photo')}
                    </Button>
                </Box>
            </Box>
            {values && (
                <Box>
                    {values.person_type === 'NP' ? (
                        <BasicData values={values} />
                    ) : (
                        <BasicDataLP values={values} />
                    )}
                    <SpecificData values={values} />
                    <SocialData values={values} />
                </Box>
            )}
        </Box>
    )
}

export default EditProfile;