import { Box, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { getUserId } from '../services/auth';
import { contactProfessional } from '../services/CatalogoService';
import AuthenticateDlg from './AuthenticateDlg';
import { useTranslation } from "react-i18next";
import ErrorMessage from './ErrorMessage';

/** Botão para contactar um profissiona */
const ContactProfessional = (props: any) => {
    const { recipient, ...others } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [showLogin, setShowLogin] = useState(false);
    const { t } = useTranslation();

    const handleContact = () => {
        const sender = getUserId();
        if (sender) {
            setIsLoading(true);
            contactProfessional({ sender: sender, recipient: recipient },
                (response: any) => {
                    setIsLoading(false);
                    setIsSuccess(true);
                },
                (err: any) => {
                    setIsLoading(false);
                    setIsSuccess(false);
                    console.error(err);
                })
        } else {
            setShowLogin(true);
        }
    }

    return (
        <Box>
            <AuthenticateDlg open={showLogin} onClose={() => setShowLogin(false)}/>
            <ErrorMessage isLoading={isLoading} isSuccess={isSuccess}
                onClose={() => setIsSuccess(null)}
                errorMsg="Ocorreu um erro ao tentar conectar ao usuário"
                successMsg="Pedido de conexão enviado com sucesso para o profissional" />
            <Button variant="contained" color="primary" {...others}
                onClick={() => handleContact()}>
                {t("findprofessionals.sendemail")}
            </Button>
        </Box>
    )
}

export default ContactProfessional;