import { Box, Button, CardActions, CardHeader, Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import RoomIcon from '@material-ui/icons/Room';
import { Link } from "react-router-dom";
import ContactProfessional from "../ContacProfessional";
import IsFav from "../IsFav";
import ShareButton from "../ShareButton";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import { UserCardProps } from "./UserCardProps";



const UserCard: React.FC<UserCardProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Card className={classes.root}>
            <CardHeader action={
                <Box justifyContent="right">
                    <IsFav professionalId={props.id}  />
                    <ShareButton url={`${window.location.href}/${props.id}`} title={props.name || ''}/>
                </Box>} />
            <CardContent className={classes.cardContent}>
                <Box display="-webkit-flex" alignItems="flex-start">
                    <Avatar alt={props.name} src={props.avatar_url} className={classes.avatar} />
                    <Box display="inline-flex" mr={1}>
                        <Grid container>
                            <Grid item xs={12} className={classes.name}>
                                {props.name}
                                {/* <VerifiedUserIcon className={classes.icon} /> */}
                            </Grid>
                            <Grid item xs={12} className={classes.person_type}>
                                {props.person_type === 'NP' ? t("findprofessionals.professional") : t('findprofessionals.company')}
                            </Grid>
                            <Grid item xs={12} className={classes.location}>
                                <RoomIcon className={classes.icon} />
                                {props.location?.name} - {props.location?.state?.acronym}
                            </Grid>
                            <Grid item xs>
                                <Box display="-webkit-flex" flexWrap="wrap">
                                    {props.areas?.map(area => 
                                        <Box key={area} className={classes.area}>
                                            {area}
                                        </Box>)}
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>
                </Box>
            </CardContent>
            <CardActions>
                <Box display="-webkit-flex" justifyContent="space-between" width="100%">
                    <Button variant="outlined" size="small" color="primary"
                        component={Link} to={`/guia-audiovisual/${props.id}`} target="_self">
                        {t("findprofessionals.viewprofile")}
                    </Button>
                    <ContactProfessional recipient={props.id} size="small" />
                </Box>
            </CardActions>
        </Card>
    );
};

export default UserCard;
