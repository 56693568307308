import { createStyles, Popover, IconButton, makeStyles, Theme, Box } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/ShareOutlined';
import React, { useState } from 'react';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            color: theme.palette.primary.light,
        },
        shareBox: {
            '& > *': {
                margin: theme.spacing(1),
            }
        }
    }));

const ShareButton: React.FC<{ url: string, title: string }> = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const openShareDlg = Boolean(anchorEl);

    const ShareDlg = (props: any) => {
        return (
            <Popover open={props.open} onClose={props.onClose} anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}>
                <Box boxShadow={3} borderRadius="8px" className={classes.shareBox}>
                    <FacebookShareButton url={props.url} hashtag="telaamazonia" quote={props.title}>
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <WhatsappShareButton url={props.url} title={props.title}>
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                    <TelegramShareButton url={props.url} title={props.title}>
                        <TelegramIcon size={32} round />
                    </TelegramShareButton>
                    <TwitterShareButton url={props.url} title={props.title} hashtags={['telaamazonia']}>
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <EmailShareButton url={props.url} subject={props.title}>
                        <EmailIcon size={32} round />
                    </EmailShareButton>
                </Box>
            </Popover>
        )
    }

    return (
        <>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} >
                <ShareIcon className={classes.icon} />
            </IconButton>
            <ShareDlg open={openShareDlg} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}
                url={props.url} title={props.title} />
        </>
    )
}

export default ShareButton;