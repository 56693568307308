import { Box, Button } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { PencilFill, Save } from 'react-bootstrap-icons';
import theme from '../theme';

const Title: FC<{
    title: string,
    hiddeButton?: boolean,
    onChangeEditMode?: (isEditing: boolean) => void
}> = ({ title, hiddeButton = false, onChangeEditMode }) => {
    const [isEditing, setIsEditing] = useState(false)
    const handleClick = (e: any) => {
        const value = !isEditing;
        setIsEditing(value);
        if (onChangeEditMode) {
            onChangeEditMode(value);
        }
    }
    return (
        <Box borderBottom={1} pb={1} display="-webkit-flex" justifyContent="space-between"
            color={theme.palette.primary.main} mt={4} mb={2} alignItems="center">
            <Box fontSize="h6.fontSize" fontWeight={600}>
                {title.toUpperCase()}
            </Box>
            { !hiddeButton &&
                (isEditing ?
                    <Button variant="contained" color="primary" size="small"
                        endIcon={<Save />} onClick={handleClick}>Salvar</Button>
                    :
                    <Button variant="contained" color="primary" size="small"
                        endIcon={<PencilFill />} onClick={handleClick}>Editar</Button>
                )
            }
        </Box>
    )
}

export default Title;