import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        button: {
            color: 'black',
            fontWeight: 600,
            backgroundColor: 'transparent',
            textTransform: 'none',
            '&:hover': {
                color: '#005b9f',
                backgroundColor: 'transparent',
            }
        },
        appBar: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            backgroundColor: "white",
            zIndex: theme.zIndex.drawer + 1,
            padding: '3px 0',
        },
        appBarHome: {
            backgroundColor: "transparent",
            zIndex: theme.zIndex.drawer + 1,
        },
        toolbar: {
            '& a': {
                textTransform: "none",
                margin: theme.spacing(1)
            },
        },
        barOffset: {
            minHeight: 91,
        },
        title: {
            flexGrow: 1,
        },
    })
);

export default useStyles;