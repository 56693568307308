import { createStyles, makeStyles, Theme } from "@material-ui/core"
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import CustomBreadcrumb, { Trail } from '../../components/CustomBreadcrumb'
import { getPageBySlug } from "../../services/BlogServices"


const useStyles = makeStyles((theme: Theme) => createStyles({
    pageContent: {
        '& h1, h2, h3, h4': {
            color: '#42ae9f'

        },
        '& h1': {
            textTransform: 'uppercase'
        },
        '& p': {
            color: '#4f4f4f'
        }
    }
}))

const QuemSomos = () => {
    const [pageContent, setPageContent] = useState<string>('')
    const classes = useStyles();
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        getPageBySlug('about', (success: any) => {
            setPageContent(success.data.content)
        },
            (err: any) => {
                console.error(err)
            })
    }, [])

    useEffect(() => {
        if (location.hash.toLowerCase() === '#equipe') {
            const equipe = document.getElementById('equipe');
            equipe?.scrollIntoView();
        }
        else if (location.hash.toLowerCase() === '#plataforma') {
            const plataforma = document.getElementById('plataforma');
            plataforma?.scrollIntoView();
        }
    }, [pageContent, location]);

    const trail: Trail[] = [
        { label: t('breadcrumb.home'), link: '/' },
        { label: t('breadcrumb.aboutus') }
    ];

    return (
        <Container maxWidth="lg">
            <CustomBreadcrumb trail={trail} />
            <Typography
                color="textSecondary"
                gutterBottom
                dangerouslySetInnerHTML={{ __html: pageContent }}
                className={classes.pageContent} />
        </Container>
    )
}

export default QuemSomos;