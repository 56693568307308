import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(3)
        },
        selectedBox: {
            backgroundColor: '#ccc',
            color: '#4d4d4d',
            padding: 8,
            fontSize: 'small',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 2,
            borderRadius: 4,
            '& svg': {
                cursor: 'pointer',
                fontSize: 'small'
            }
        }
    })
);

export default useStyles;