import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: theme.palette.primary.light,
        },
        option: {
            color: theme.palette.grey[400],
            cursor: 'pointer',
        },
        selectedOption: {
            color: theme.palette.grey[900],
            cursor: 'pointer',
        }
    })
);

export default useStyles;