import { Box, TextField } from '@material-ui/core';
import React, { FC, useState } from 'react';
import ErrorMessage from '../../components/ErrorMessage';
import Fieldset from '../../components/Fieldset';
import Title from '../../components/Title';
import { Professional } from '../../models';
import { useTranslation } from 'react-i18next';
import { changeEmail, changePassword, updateHirer } from '../../services/CatalogoService';

interface RequestResult {
    field?: string;
    isSuccess: boolean | null,
    errorMsg?: string,
    successMsg?: string
}

const AccountSettings: FC<{ values: Professional }> = ({ values, ...props }) => {
    const [editInfo, setEditInfo] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const [editPassword, setEditPassword] = useState(false);
    const [oldEmail, setOldEmail] = useState(values.email);
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [name, setName] = useState(values.name);
    const [isLoading, setIsLoading] = useState(false);
    const [requestResult, setRequestResult] = useState<RequestResult>({ isSuccess: null });
    const [confirmValidate, setConfirmValidate] = useState<RequestResult>({ isSuccess: null });
    const { t } = useTranslation();

    const handleOnChange = (e: any) => {
        const validate = { ...confirmValidate };
        switch (e.target.name) {
            case 'email':
                setEmail(e.target.value);
                break;
            case 'confirmEmail':
                setConfirmEmail(e.target.value);
                validate.field = 'email';
                if (email !== String(e.target.value)) {
                    validate.isSuccess = false;
                    validate.errorMsg = t('profile.diff_password');
                } else {
                    validate.isSuccess = true;
                    validate.errorMsg = '';
                }
                setConfirmValidate(validate);
                break;
            case 'oldPassword':
                setOldPassword(e.target.value);
                break;
            case 'password':
                setPassword(e.target.value);
                break;
            case 'confirmPassword':
                setConfirmPassword(e.target.value);
                validate.field = 'password';
                if (password !== String(e.target.value)) {
                    validate.isSuccess = false;
                    validate.errorMsg = t('profile.diff_password');;
                } else {
                    validate.isSuccess = true;
                    validate.errorMsg = '';
                }
                setConfirmValidate(validate);
                break;
            case 'name':
                setName(e.target.value);
                validate.field = 'name';
                if (e.target.value === '') {
                    validate.isSuccess = false;
                    validate.errorMsg = t('profile.empty_password');
                } else {
                    validate.isSuccess = true;
                    validate.errorMsg = '';
                }
                setConfirmValidate(validate);
                break;
            default:
                break;
        }
    }

    const handleEditEmail = (value: boolean) => {
        setEditEmail(value);
        if (!value) {
            if (email === confirmEmail) {
                const data = {
                    id: values.id,
                    email: email,
                }
                setIsLoading(true);
                changeEmail(data,
                    (response: any) => {
                        setIsLoading(false);
                        setRequestResult({
                            isSuccess: true,
                            successMsg: t('profile.success.email')
                        });
                        setOldEmail(email);
                        setEmail('');
                        setConfirmEmail('');
                    },
                    (e: any) => {
                        setIsLoading(false);
                        const errors: any[] = []
                        const data = e.response.data;
                        if (data.email) {
                            errors.push(...data.email);
                        }
                        
                        setRequestResult({
                            isSuccess: false,
                            errorMsg: `${t('profile.error.email')} ${errors.join(', ')}`
                        });
                        console.error(e);
                        setEmail('');
                        setConfirmEmail('');
                    });
            }
        }
    }

    const handleEditPassword = (value: boolean) => {
        setEditPassword(value);
        if (!value) {
            if (password === confirmPassword) {
                const data = {
                    id: values.id,
                    password: password,
                    password2: confirmPassword,
                    old_password: oldPassword
                }
                setIsLoading(true);
                changePassword(data,
                    (response: any) => {
                        setIsLoading(false);
                        setRequestResult({
                            isSuccess: true,
                            successMsg: t('profile.success.password'),
                        });
                        setOldPassword('');
                        setPassword('');
                        setConfirmPassword('');
                    },
                    (e: any) => {
                        setIsLoading(false);
                        const errors: any[] = []
                        const data = e.response.data;
                        if (data.old_password) {
                            errors.push(data.old_password.old_password);
                        }
                        if (data.password) {
                            errors.push(...data.password);
                        }
                        
                        setRequestResult({
                            isSuccess: false,
                            errorMsg: `${t('profile.error.password')} ${errors.join(', ')}`,
                            successMsg: undefined
                        });
                        console.error(e);
                        setOldPassword('');
                        setPassword('');
                        setConfirmPassword('');
                    });

            }
        }
    }

    const handleEditInfo = (value: boolean) => {
        setEditInfo(value);
        if (name !== '' && !value) {
            setIsLoading(true);
            updateHirer({id: values.id, name: name},
                (response: any) => {
                    setIsLoading(false);
                    setRequestResult({
                        isSuccess: true,
                        successMsg: t('profile.success.name'),
                    });
                },
                (err: any) => {
                    setIsLoading(false);
                    setRequestResult({
                        isSuccess: false, 
                        errorMsg: `${t('profile.error.name')} ${err.message}`,
                        successMsg: undefined
                    });
                    console.error(err);
                })
        } else if (name === '' ) {
            setRequestResult({
                isSuccess: false,
                errorMsg: t('profile.error.empty_username'),
                successMsg: undefined
            });
        }
    }

    const handleCloseMessage = (e: any) => {
        setRequestResult({ ...requestResult, isSuccess: null });
    };

    return (
        <Box>
            <ErrorMessage isLoading={isLoading} isSuccess={requestResult.isSuccess}
                errorMsg={requestResult.errorMsg} successMsg={requestResult.successMsg}
                onClose={handleCloseMessage} duration={5000}/>
            <Box>
                <Box hidden={values?.account_type !== 'HIRER'}>
                    <Title title={t('profile.change_info')} onChangeEditMode={handleEditInfo}/>
                    <Fieldset disabled={!editInfo}>
                        <TextField name="name" value={name} label={t('register.name')}
                            onChange={handleOnChange} fullWidth required
                            error={confirmValidate.isSuccess === false && confirmValidate.field === 'name'}
                            helperText={confirmValidate.field === 'name' ? confirmValidate.errorMsg : ''}/>
                    </Fieldset>
                </Box>
                <Title title={t('profile.change_email')} onChangeEditMode={handleEditEmail} />
                <Fieldset disabled={!editEmail}>
                    <TextField name="oldEmail" value={oldEmail} label={t('profile.old_email')}
                        onChange={handleOnChange} fullWidth required/>
                    <TextField name="email" value={email} label={t('profile.new_email')}
                        onChange={handleOnChange} fullWidth required/>
                    <TextField name="confirmEmail" value={confirmEmail} label={t('profile.confirmation_email')}
                        onChange={handleOnChange} error={confirmValidate.isSuccess === false && confirmValidate.field === 'email'}
                        helperText={confirmValidate.field === 'email' ? confirmValidate.errorMsg : ''}
                        fullWidth required/>
                </Fieldset>
            </Box>
            <Box pb={4}>
                <Title title={t('profile.change_password')} onChangeEditMode={handleEditPassword} />
                <Fieldset disabled={!editPassword}>
                    <TextField name="oldPassword" value={oldPassword} label={t('profile.old_password')}
                        onChange={handleOnChange} fullWidth type="password" required/>
                    <TextField name="password" value={password} label={t('profile.new_password')}
                        onChange={handleOnChange} fullWidth type="password" required/>
                    <TextField name="confirmPassword" value={confirmPassword} label={t('profile.confirmation_password')}
                        onChange={handleOnChange} error={confirmValidate.isSuccess === false && confirmValidate.field === 'password'}
                        helperText={confirmValidate.field === 'password' ? confirmValidate.errorMsg : ''}
                        fullWidth type="password" required/>
                </Fieldset>
            </Box>
        </Box>
    )
}

export default AccountSettings;