import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import GuiaAudioVisual from "./pages/GuiaAudioVisual";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import ProfessionalDetail from "./pages/ProfessionalDetail";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import RegisterForm from "./pages/SignUp/RegisterForm";
import ContactUs from "./pages/ContactUs/ContactUs";
import QuemSomos from "./pages/QuemSomos"
import Observatory from "./pages/Observatory/Observatory";
import Post from "./pages/Observatory/Post";
import SimplePage from "./components/SimplePage";
import { useTranslation } from "react-i18next";
import { getLanguage } from "./components/LanguageSelector";

/**
 * Mapa usado par identificar a pagina interna de acordo com o idioma selecionado
 * Os valores do 'slug' das paginas são pegas de acordo com o valor salvo no banco
 * de dados do backend no campo slug. https://api.telaamazonia.com/admin/blog/page/
 */
const SlugMap = new Map([
  [
    'pt-BR',
    {
      plataforma: 'a-plataforma',
      equipe: 'equipe',
      politica: 'politica-de-privacidade',
      termos: 'termos-e-condicoes'
    }
  ],
  [
    'es-ES',
    {
      plataforma: 'a-plataforma-es-es',
      equipe: 'equipe-es-es',
      politica: 'politica-de-privacidade',
      termos: 'termos-e-condicoes'
    }
  ],
  [
    'en-US',
    {
      plataforma: 'a-plataforma-en-us',
      equipe: 'equipe-en-us',
      politica: 'politica-de-privacidade',
      termos: 'termos-e-condicoes'
    }
  ]
]);

const Routes = withRouter(({ location }) => {
  const { t } = useTranslation();


  const lang: string = getLanguage() || 'pt-BR';
  const slugLang = SlugMap.get(lang);

  const Plataforma = () => {
    const breadcrumbs = [
      { link: '/', label: t('breadcrumb.home') },
      { label: t('breadcrumb.platform') }
    ]
    return <SimplePage slug={slugLang!.plataforma} breadcrumbs={breadcrumbs} />
  }

  const Equipe = () => {
    const breadcrumbs = [
      { link: '/', label: t('breadcrumb.home') },
      { label: t('breadcrumb.team') }
    ]
    return <SimplePage slug={slugLang!.equipe} breadcrumbs={breadcrumbs} />
  }

  const PoliticaPrivacidade = () => {
    const breadcrumbs = [
      { link: '/', label: t('breadcrumb.home') },
      { label: t('breadcrumb.privacy') }
    ]
    return <SimplePage slug={slugLang!.politica} breadcrumbs={breadcrumbs} />
  }

  const Termos = () => {
    const breadcrumbs = [
      { link: '/', label: t('breadcrumb.home') },
      { label: t('breadcrumb.terms') }
    ]
    return <SimplePage slug={slugLang!.termos} breadcrumbs={breadcrumbs} />
  }

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/guia-audiovisual" component={GuiaAudioVisual} />
      <Route exact path="/guia-audiovisual/:id" component={ProfessionalDetail} />
      <Route exact path="/cadastro-usuario" component={SignUp} />
      <Route exact path="/cadastro-usuario/segmentacao" component={RegisterForm} />
      <Route path="/autenticacao-usuario" component={SignIn} />
      <Route path="/perfil" component={Profile} />
      <Route exact path="/observatorio/:id" component={Post} />
      <Route exact path="/observatorio" component={Observatory} />
      <Route path="/sobre" component={QuemSomos} />
      <Route path="/equipe" component={Equipe} />
      <Route path="/plataforma" component={Plataforma} />
      <Route path="/termos" component={Termos} />
      <Route path="/politica" component={PoliticaPrivacidade} />
      <Route path="/fale-conosco" component={ContactUs} />
      <PrivateRoute path="/admin" component={() => <h1>App</h1>} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
});

export default Routes;
