import { FormControl, Input, InputLabel } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import { PhoneInputProps, MaskedComponentProps } from './PhoneInputProps';

function FoneInput(props: MaskedComponentProps) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'_'}
            showMask
        />
    )
}

const PhoneInput: FC<PhoneInputProps> = (props) => {
    const { t } = useTranslation();
    const { label = t('phone.label') } = props;
    return <FormControl>
        <InputLabel htmlFor="foneId">{label}</InputLabel>
        <Input
            value={props.value}
            onChange={(e: any) => { if (props.onChange) props.onChange(e) }}
            name={props.name}
            id="foneId"
            onFocus={(e) => e.target.select()}
            inputComponent={FoneInput as any}
        />
    </FormControl>
}

export default PhoneInput;
