import { Box } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterExpertise from '../../../components/FilterExpertise';
import FilterSelect, { FilterOption } from '../../../components/FilterSelect';
import { Expertise } from '../../../models';
import { fetchExpertises2, fetchSpecialities } from '../../../services/CatalogoService';

export interface Step2Props {
    expertises?: Expertise[];
    specialities?: FilterOption[];
}

/**
 * Segundo passo do formulário de cadastro
 * @returns
 */
const Step2: FC<{ values: Step2Props, onChangeValues: (values: Step2Props) => void }> = (props) => {

    const [expertiseOptions, setExpertiseOptions] = useState<Array<any>>([]);
    const [specialitiesOptions] = useState<Array<FilterOption>>([]);
    const [expertises, setExpertises] = useState<Array<Expertise>>([]);
    const [specialities, setSpecialities] = useState(props.values.specialities || []);
    const [data, setData] = useState<Step2Props>(props.values);
    const { t } = useTranslation();

    /** Carrega os as areas de conhecimento */
    useEffect(() => {
        fetchExpertises2(
            (data: Array<Expertise>) => {
                const sort = data.sort((a, b) => a.knownledge_field.name.localeCompare(b.knownledge_field.name));
                setExpertiseOptions([...sort]);
            },
            (err: any) => { console.error((err)) });
    }, []);

    /** Inicializa expertises */
    useEffect(() => {
        if (props.values.expertises && expertiseOptions.length > 0) {
            setExpertises(props.values.expertises);
        }
    }, [expertiseOptions, props.values.expertises]);

    /** Carrega as especialidades */
    useEffect(() => {
        fetchSpecialities(
            (data: Array<FilterOption>) => {
                specialitiesOptions.length = 0;
                specialitiesOptions.push(...data);
            },
            (err: any) => { console.error(err) }
        )
    }, [specialitiesOptions]);

    function handleChangeExpertise(values: any[]) {
        const selectedList: Array<Expertise> = [];
        values.forEach(item => {
            const { group, ...expertise } = item;
            selectedList.push(expertise)
        });
        setExpertises([...selectedList]);
        const formData = { ...data, expertises: selectedList };
        setData(formData);
        props.onChangeValues(formData);
    }

    const hadleChangeSpeciality = (values: Array<FilterOption>) => {
        const formData = { ...data, specialities: values };
        setData(formData);
        setSpecialities(values);
        props.onChangeValues(formData);
    }

    return (
        <Box width="100%">
            <Box mb={4}>
                <FilterExpertise options={expertiseOptions}
                    OnChangeFilter={handleChangeExpertise}
                    initValues={expertises} />
            </Box>
            <FilterSelect options={specialitiesOptions} title={t('findprofessionals.speciality')}
                OnChangeFilter={hadleChangeSpeciality} initValues={specialities}
                placeholder={t('findprofessionals.placeholder.specialty')} />
        </Box>
    )
}

export default Step2;
