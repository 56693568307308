import React, { useState } from 'react'
import StarIcon from '@material-ui/icons/Star';
import StarIconOutiline from '@material-ui/icons/StarOutline';

import { createStyles, IconButton, makeStyles, Theme, CircularProgress } from "@material-ui/core";
import { isAuthenticated } from '../services/auth';
import AuthenticateDlg from './AuthenticateDlg';
import { addFavorite, removeFavorite, userFavList } from '../services/CatalogoService';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            color: theme.palette.primary.light,
        }
    }));

const IsFav: React.FC<{ professionalId: number }> = ({ professionalId }) => {
    const [showLogin, setShowLogin] = useState(false);
    const [isFavorite, setFavorite] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const refreshFav = () => {
        const isFav = userFavList().findIndex(item => Number(item.id) === professionalId) > -1;
        setFavorite(isFav);
    }

    useEffect(() => {
        refreshFav();
    }, [professionalId])


    const classes = useStyles();

    const handleClick = (e: any) => {
        if (!isAuthenticated()) {
            setShowLogin(true);
        } else {
            setLoading(true);
            if (isFavorite) {
                removeFavorite(professionalId,
                    (response: any) => {
                        setLoading(false);
                        setFavorite(false);
                    },
                    (error: any) => {
                        setLoading(false);
                        console.log('Falha ao remover favorito');
                    })
            } else {
                addFavorite(professionalId,
                    (response: any) => {
                        setLoading(false);
                        setFavorite(true);
                    },
                    (error: any) => {
                        setLoading(false);
                        console.log('Falha ao adicionar favorito');
                    })
            }
        }
    }

    return (
        <>
            <AuthenticateDlg open={showLogin} onClose={() => setShowLogin(false)} />
            { !isLoading ?
                (
                    <IconButton onClick={handleClick}>
                        {
                            isFavorite ?
                                <StarIcon className={classes.icon} /> :
                                <StarIconOutiline className={classes.icon} />
                        }
                    </IconButton>
                ) : (
                    <IconButton disabled={true}>
                        <CircularProgress size={20} />
                    </IconButton>
                )
            }
        </>
    );
}

export default IsFav;