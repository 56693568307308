import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { FilterOption } from './FilterSelect';

function CustomSelect(props: any) {
    const { label, name, ...others } = props;
    const options: FilterOption[] = [...props.options];
    const value: any = options.length === 0 ? '' : props.value;
    return (
        <FormControl fullWidth margin="normal">
            <InputLabel id={`${name}Id`} shrink>
                {label}
            </InputLabel>
            <Select
                {...others}
                labelId={`${name}Id`}
                name={name}
                value={value}
                style={{ minWidth: 120 }}
            >
                {options.map((item) => {
                    return <MenuItem key={item.label} value={item.value}>
                        {item.label}
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    )
}

export default CustomSelect;