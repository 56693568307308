import { Backdrop, Box, Button, CircularProgress, Snackbar, Step, StepLabel, Stepper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as dateFns from 'date-fns';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { BusinessSize, City, Language, Professional, Speciality, State } from '../../models';
import { updateProfessional } from '../../services/CatalogoService';
import Step1, { Step1Props } from './Steps/Step1';
import Step1LP, { Step1LPProps } from './Steps/Step1LP';
import Step2, { Step2Props } from './Steps/Step2';
import Step3, { Step3Props } from './Steps/Step3';
import useStyles from './styles';

interface FormData {
    step1: Step1Props,
    step1LP: Step1LPProps,
    step2: Step2Props,
    step3: Step3Props,
}

const RegisterStepper: FC<{ user: Professional }> = (props) => {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = [t('register.social'), t('register.acting'), t('register.biography')];
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [data, setData] = useState<FormData>({
        step1: {},
        step1LP: { name: props.user.name },
        step2: {},
        step3: {}
    });
    const history = useHistory();
    const classes = useStyles();

    const handelStep1Change = (values: Step1Props) => {
        setData({ ...data, step1: values });
    }

    const handelStep1LPChange = (values: Step1LPProps) => {
        setData({ ...data, step1LP: values });
    }

    const handelStep2Change = (values: Step2Props) => {
        setData({ ...data, step2: values });
    }

    const handelStep3Change = (values: Step3Props) => {
        setData({ ...data, step3: values });
    }

    function isValid(step: number) {
        let isValid = false;
        switch (step) {
            case 0:
                if (props.user.person_type === 'NP') {
                    const step1 = data.step1;
                    isValid = step1.name !== undefined && step1.name !== '';
                    isValid = isValid && step1.birthday !== undefined &&
                        step1.birthday instanceof (Date) && !isNaN(step1.birthday.getDate());
                    isValid = isValid && step1.gender !== undefined;
                    isValid = isValid && step1.languages !== undefined && step1.languages.length > 0;
                } else {
                    const step1 = data.step1LP;
                    isValid = step1.name !== undefined && step1.name !== '';
                    isValid = isValid && step1.cnpj !== undefined && step1.cnpj.length === 15;
                    isValid = isValid && step1.corporateName !== undefined && step1.corporateName !== '';
                    isValid = isValid && step1.representative !== undefined && step1.representative !== '';
                    isValid = isValid && step1.businessSize !== undefined;
                    isValid = isValid && step1.languages !== undefined && step1.languages.length > 0;
                }
                break;
            case 1:
                const step2: Step2Props = data.step2;
                isValid = step2.specialities !== undefined && step2.specialities.length > 0;
                isValid = isValid && step2.expertises !== undefined && step2.expertises.length > 0;
                break;
            default:
                const step3: Step3Props = data.step3;
                isValid = step3.fone !== undefined && step3.fone !== '';
                isValid = step3.resume !== undefined && step3.resume !== '';
                isValid = isValid && step3.city !== undefined && step3.city.value !== '';
                isValid = isValid && step3.state !== undefined && step3.state.acronym !== '';
                break;
        }
        return isValid;
    }

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                if (props.user.person_type === 'NP') {
                    return <Step1 values={data.step1} onChangeValues={handelStep1Change} />;
                } else {
                    return <Step1LP values={data.step1LP} onChangeValues={handelStep1LPChange} />;
                }
            case 1:
                return <Step2 values={data.step2} onChangeValues={handelStep2Change} />;
            case 2:
                return <Step3 values={data.step3} onChangeValues={handelStep3Change} />;
            default:
                return 'Unknown step';
        }
    }

    /** Finaliza o cadastro e salva os dados do profissional */
    function finish() {
        setIsLoading(true);
        const specialities: Array<Speciality> = [];
        data.step2.specialities?.forEach(item => {
            specialities.push({ id: Number(item.value), name: item.label });
        });
        const languages: Array<Language> = [];
        if (props.user.person_type === 'NP') {
            data.step1.languages?.forEach(item => {
                languages.push({ id: Number(item.value), name: item.label });
            });
        } else {
            data.step1LP.languages?.forEach(item => {
                languages.push({ id: Number(item.value), name: item.label });
            });
        }
        const subject_areas: Array<string> = [];
        data.step2.expertises?.forEach(item => subject_areas.push(item.name));
        const state: State | null = data.step3.state ? data.step3.state : null;
        const city: City = {
            id: Number(data.step3.city?.value),
            name: String(data.step3.city?.label),
            state: state
        };
        const pro: Professional = props.user;
        if (data.step1.gender) {
            pro.gender = { id: Number(data.step1.gender?.value), name: data.step1.gender?.label }
        } else {
            pro.gender = undefined;
        }
        if (data.step1.birthday) {
            pro.date_of_birth = dateFns.format(data.step1.birthday, 'yyyy-MM-dd');
        }
        const businessSize: BusinessSize | undefined = data.step1LP.businessSize ?
            {
                id: Number(data.step1LP.businessSize?.value),
                name: data.step1LP.businessSize?.label
            } : undefined;
        pro.details = {
            professional_resume: data.step3.resume || '',
            specialities_resume: '',
            speciality: specialities,
            languages: languages,
            expertises: data.step2.expertises || [],
            subject_areas: [],
            phone_number: data.step3.fone || '',
            business_size: businessSize,
            corporate_name: pro.person_type === 'NP' ? data.step1.name : data.step1LP.corporateName,
            cpf_cnpj: data.step1LP.cnpj,
            representative: data.step1LP.representative,
            social_networks: [],
            city: city,
        }

        updateProfessional(pro,
            (response) => {
                setIsLoading(false);
                setIsSuccess(true);
            },
            (e) => {
                setIsLoading(false);
                setIsSuccess(false);
                console.error(e);
            });
    }

    /** Fecha o Snackbar e redireciona para o perfil do usuario em caso de sucesso */
    function onFinish() {
        setIsSuccess(null);
        if (isSuccess) {
            history.push(`/perfil`);
        }
    }

    const handleNext = () => {
        if (activeStep < steps.length - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            finish();
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box width="100%">
            <Backdrop open={isLoading} invisible className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={isSuccess !== null} autoHideDuration={2000}
                onClose={(e) => onFinish()}>
                {
                    isSuccess ? (
                        <Alert severity="success" onClose={(e) => onFinish()}>
                            {t('register.success')}
                        </Alert>
                    ) : (
                        <Alert severity="error" onClose={(e) => onFinish()}>
                            {t('register.error')}                            
                        </Alert>
                    )
                }
            </Snackbar>
            <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}> {label} </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <Box>
                {getStepContent(activeStep)}
                <Box my={4} pt={2} borderTop={2} borderColor="green"
                    justifyContent="space-between" display="-webkit-flex">
                    <Button disabled={activeStep === 0}
                        onClick={handleBack}
                        variant="outlined"
                        size="large"
                    >
                        {t('register.back')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        size="large"
                        disabled={!isValid(activeStep) || isLoading}
                    >
                        {activeStep === steps.length - 1 ? t('register.finish') : t('register.continue')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default RegisterStepper;