import { Backdrop, Box, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import theme from '../../theme';
import { ErrorMessageProps } from './ErrorMessageProps';
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            backdrop: {
                zIndex: theme.zIndex.drawer + 1,
                color: '#fff',
            },
        })
    );
    const classes = useStyles();
    const {
        errorMsg = 'Algo inesperado aconteceu.',
        successMsg = 'Operação realizada com Sucesso!',
        duration = 2000
    } = { ...props };

    return (
        <Box>
            <Backdrop open={props.isLoading} invisible className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={props.isSuccess !== null} autoHideDuration={duration}
                onClose={props.onClose}>
                <Box>
                    {
                        props.isSuccess && (
                            <Alert severity="success" onClose={props.onClose}>
                                {successMsg}
                            </Alert>
                        )
                    }
                    {
                        props.isSuccess === false && (
                            <Alert severity="error" onClose={props.onClose}>
                                {errorMsg}
                            </Alert>
                        )
                    }
                </Box>
            </Snackbar>
        </Box>
    )
}

export default ErrorMessage;
