import { Box, Button, Container, FormControl, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { LANG_KEY } from '.';
import { LanguageSelectorProps } from './LanguageSelectorProps';
import useStyles from './style'

const LanguageSelector: React.FC<LanguageSelectorProps> = (props) => {

    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(props.language);
    const [isSelecting, setIsSelecting] = useState(false);
    const [open, setOpen] = useState(true);
    const classes = useStyles();
    const right = props.right || '25%';

    const languages = new Map<string, any>([
        ['pt-BR', { label: 'PT', flag: 'https://catamphetamine.gitlab.io/country-flag-icons/3x2/BR.svg' }],
        ['en-US', { label: 'EN', flag: 'https://catamphetamine.gitlab.io/country-flag-icons/3x2/US.svg' }],
        ['es-ES', { label: 'ES', flag: 'https://catamphetamine.gitlab.io/country-flag-icons/3x2/ES.svg' }]
    ])

    useEffect(() => {
        i18n.changeLanguage(language);
        localStorage.setItem(LANG_KEY, language);
    }, [language, i18n])

    function renderOptions() {
        const options: any[] = []
        languages.forEach((value, key) => {
            options.push((
                <MenuItem key={key} value={key}>
                    <img src={value.flag} alt={value.label} width="24" />&nbsp;{value.label}
                </MenuItem>)
            )
        })
        return options;
    }

    function handleSelection(value: boolean) {
        setIsSelecting(value);
        setOpen(value);
    }

    const handleChange = (e: any) => {
        const lang = e.target.value;
        setLanguage(lang);
        if (props.onChange) {
            props.onChange(lang);
        }
    }

    function renderSelect() {
        return (
            <FormControl >
                <Select value={language}
                    onChange={handleChange}
                    variant="outlined"
                    open={open}
                    onClose={() => handleSelection(false)}
                >
                    {renderOptions()}
                </Select>
            </FormControl>
        );
    }

    return (
        <Box right={right} boxShadow={2} className={classes.root}>
            <Box hidden={isSelecting}>
                <Button onClick={() => handleSelection(true)}
                    disableFocusRipple={true}>
                    <img src={languages.get(language).flag}
                        alt={languages.get(language)} width="24" />&nbsp;
                    {languages.get(language).label}
                </Button>
            </Box>
            {isSelecting && renderSelect()}
        </Box>
    )
}

export default LanguageSelector;