import { Box } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../../components/ErrorMessage';
import FilterExpertise from '../../../components/FilterExpertise';
import FilterSelect, { FilterOption } from '../../../components/FilterSelect';
import Title from '../../../components/Title';
import { Expertise, KnownledgeField, Professional, Speciality } from '../../../models';
import { fetchExpertises2, fetchSpecialities, updateProfessional } from "../../../services/CatalogoService";
import useStyles from '../style';

export interface iSpecificData {
    expertises: Expertise[],
    specialities: Speciality[],
}

const SpecificData: FC<{ values: Professional, onChange?: (data: iSpecificData) => void; }> = ({ values, onChange }) => {
    const [data, setData] = useState<iSpecificData>({
        expertises: [],
        specialities: values.details?.speciality ? values.details?.speciality : []
    });
    const [expertiseOptions, setExpertiseOptions] = useState<Array<any>>([]);
    const [specialitiesOptions] = useState<Array<FilterOption>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const classes = useStyles();
    const { t }  = useTranslation();

    const expertisesCallback = useCallback(() => {
        const subjectAreas: KnownledgeField[] = values.details?.subject_areas || [];
        if (subjectAreas) {
            const expertises: Expertise[] = [];
            subjectAreas.forEach(item => {
                if (item.expertises) {
                    item.expertises.forEach(exp =>
                        expertises.push({
                            ...exp,
                            knownledge_field: {
                                id: item.id,
                                name: item.name
                            }
                        }));
                }
            })
            setData({ ...data, expertises: expertises })
        }
    }, [values.details?.subject_areas, data]);

    useEffect(() => {
        expertisesCallback();
    }, [expertiseOptions])

    /** Carrega os as areas de conhecimento */
    useEffect(() => {
        if (expertiseOptions.length === 0) {
            fetchExpertises2(
                (data: Array<Expertise>) => {
                    const sort = data.sort((a, b) => a.knownledge_field.name.localeCompare(b.knownledge_field.name));
                    setExpertiseOptions([...sort]);
                },
                (err: any) => { console.error((err)) });
        }
    }, [expertiseOptions]);

    /** Inicializa expertises */
    useEffect(() => {
        if (values.details?.expertises && expertiseOptions.length > 0) {
            setData({ ...data, expertises: values.details?.expertises });
        }
    }, [expertiseOptions, values.details?.expertises, data]);

    /** Carrega as especialidades */
    useEffect(() => {
        fetchSpecialities(
            (data: Array<FilterOption>) => {
                specialitiesOptions.length = 0;
                specialitiesOptions.push(...data);
            },
            (err: any) => { console.error(err) }
        )
    }, [specialitiesOptions]);

    const selectedSpecialities = useMemo(getSelectSpecialities, [values.details?.speciality])

    function getSelectSpecialities() {
        const selected: FilterOption[] = [];
        if (values.details?.speciality && values.details?.speciality.length > 0) {
            values.details.speciality.forEach(item => {
                selected.push({
                    label: item.name,
                    value: item.id
                })
            })
        }
        return selected;
    }

    function handleChangeExpertise(values: any[]) {
        const selectedList: Array<Expertise> = [];
        values.forEach(item => {
            const { group, ...expertise } = item;
            selectedList.push(expertise)
        });
        const formData = { ...data, expertises: selectedList };
        setData(formData);
        if (onChange) {
            onChange(formData);
        }
    }

    const hadleChangeSpeciality = (values: Array<FilterOption>) => {
        const specialities: Speciality[] = [];
        values.forEach(item => specialities.push({ name: item.label, id: Number(item.value) }));
        const formData = { ...data, specialities: specialities };
        setData(formData);
        if (onChange) {
            onChange(formData);
        }
    }

    const saveData = () => {
        if (values) {
            const pro = { ...values, avatar: undefined };
            pro.details = {
                ...values?.details,
                languages: values.details?.languages || [],
                professional_resume: values.details?.professional_resume || '',
                subject_areas: values.details?.subject_areas || [],
                expertises: data.expertises || [],
                speciality: data.specialities || [],
                city: values.details?.city || { id: 0, name: '', state: null },
                phone_number: values.details?.phone_number || '',
            };
            updateProfessional(pro,
                (response) => {
                    setIsLoading(false);
                    setIsSuccess(true);
                },
                (e) => {
                    setIsLoading(false);
                    setIsSuccess(false);
                    console.error(e);
                });

        }

    }

    /** Fecha o Snackbar e redireciona para o perfil do usuario em caso de sucesso */
    function onFinish() {
        setIsSuccess(null);
    }

    /** Habilita os campos para edição ou salva os dados alterados */
    const handleEditMode = (value: boolean) => {
        setIsEditing(value);
        if (!value) {
            saveData();
        }
    }

    return (
        <Box>
            <Title title={t('profile.especific')} onChangeEditMode={handleEditMode} />
            <ErrorMessage isLoading={isLoading} onClose={onFinish} isSuccess={isSuccess} />
            <Box className={isEditing ? '' : classes.disabled}>
                <Box mb={4}>
                    <FilterExpertise options={expertiseOptions}
                        OnChangeFilter={handleChangeExpertise}
                        initValues={data.expertises} />
                </Box>
                <FilterSelect options={specialitiesOptions} title={t('register.specialty')}
                    OnChangeFilter={hadleChangeSpeciality} initValues={selectedSpecialities}
                    placeholder={t('register.specialtyplaceholder')} />

            </Box>
        </Box>
    )

}

export default SpecificData;
