import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import UserCard from "../UserCard";
import { UserCardListProps } from "./UserCardListProps";

/**
 * Componente ṕara listar os profissionais cadastrados
 * @returns
 */
const UserCardList: React.FC<UserCardListProps> = ({ users, loading }) => {
    if (loading) {
        return <Alert severity='info'>Carregando informações</Alert>;
    }
    return users.length > 0 ? (
        <>
            {users.map((user, index) => (
                <Grid item md={4} sm={6} xs={12} key={index}>
                    <UserCard
                        id={user.id}
                        avatar_url={user.avatar || ''}
                        name={user.details?.corporate_name || ''}
                        email={user.email}
                        person_type={user.person_type || ''}
                        areas={user.details?.subject_areas.map(item => item.name)}
                        location={user.details?.city}
                    />
                </Grid>
            ))}
        </>
    ) : (
        <Alert severity='info'>Nenhum registro encontrado</Alert>
    );
};

export default UserCardList;
