import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterOption } from '../../../components/FilterSelect';
import PhoneInput from '../../../components/PhoneInput';
import { State } from '../../../models';
import { fetchCitiesByState, fetchStates } from '../../../services/CatalogoService';

export interface Step3Props {
    resume?: string;
    state?: State;
    city?: FilterOption;
    fone?: string;
}

/**
 * PAsso 3 do formulário de cadastro
 * @returns
 */
const Step3: FC<{values: Step3Props, onChangeValues: (values: Step3Props) => void }> = (props) => {
    const [resume, setResume] = useState( props.values.resume || '');
    const [fone, setFone] = useState(props.values.fone || '');
    const [city, setCity] = useState<FilterOption>(props.values.city || { label: '', value: '' });
    const [state, setState] = useState<State>(props.values.state || { acronym: '', name: '', ibge_id: 0 });
    const [data, setData] = useState<Step3Props>(props.values);
    const [cityOptions, setCityOptions] = useState<Array<FilterOption>>([]);
    const [stateOptions, setStateOptions] = useState<Array<State>>([]);
    const { t } = useTranslation();

    /** Carrega os estados */
    useEffect(() => {
        fetchStates(
            (states: Array<State>) => {
                if (stateOptions.length < 1) {
                    setStateOptions([...states]);
                } else if (states.length === 1) {
                    setState(states[0]);
                    setData({ ...data, state: states[0] });
                }
            },
            (err: any) => { console.error((err)) });
    }, [stateOptions]);

    /** Carrega as cidades */
    useEffect(() => {
        if (state.acronym !== '') {
            fetchCitiesByState(state.acronym,
                (data: Array<FilterOption>) => {
                    setCityOptions([...data]);
                },
                (err: any) => { console.error((err)) });
        }
    }, [state]);

    /** Carrega as cidades */
    useEffect(() => {
        if (props.values.city && cityOptions.length > 0) {
            setCity(props.values.city);
        }
    }, [cityOptions, props.values.city]);

    const handleChange = (e: any) => {
        let formData: Step3Props = {};
        let value: any = '';
        let index = -1;
        switch (e.target.name) {
            case 'resume':
                value = e.target.value;
                setResume(value);
                formData = { ...data, resume: value };
                break;
            case 'fone':
                value = e.target.value as string;
                value = value.replace(/["' ()-]/g, "")
                setFone(value);
                formData = { ...data, fone: value };
                break;
            case 'state':
                index = stateOptions.findIndex(item => item.acronym === e.target.value);
                if (index > -1) {
                    value = stateOptions[index];
                    setState(value);
                    formData = { ...data, state: value };
                }
                break;
            case 'city':
                index = cityOptions.findIndex(item => item.value === e.target.value);
                if (index > -1) {
                    value = cityOptions[index];
                    setCity(value);
                    formData = { ...data, city: value };
                }
                break;
            default:
                break;
        }
        setData({ ...formData });
        props.onChangeValues(formData);
    }

    return (
        <Box>
            <TextField
                label={t('register.biography')}
                placeholder={t('register.placeholder.biography')}
                fullWidth
                multiline
                rows={10}
                value={resume}
                name="resume"
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Grid container justify="space-between" alignItems="center">
                <FormControl>
                    <InputLabel id="EstadoLable" shrink>
                        {t('register.state')}
                    </InputLabel>
                    <Select
                        labelId="EstadoLable"
                        name="state"
                        value={state.acronym}
                        onChange={handleChange}
                        style={{ minWidth: 120 }}
                    >
                        {stateOptions.map(item => {
                            return <MenuItem key={item.acronym} value={item.acronym}>
                                {item.name}
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="cidadeLable" shrink>
                        {t('register.city')}
                    </InputLabel>
                    <Select
                        labelId="cidadeLable"
                        name="city"
                        value={city.value}
                        onChange={handleChange}
                        style={{ minWidth: 120 }}
                    >
                        {cityOptions.map(item => {
                            return <MenuItem key={item.label} value={item.value}>
                                {item.label}
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
                <PhoneInput value={fone} onChange={handleChange} name="fone" />
            </Grid>
        </Box>
    );
}

export default Step3;