import { Avatar, Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import * as dateFns from 'date-fns';
import React from 'react';
import { useHistory } from 'react-router';
import theme from '../../theme';

const PostCard = (props: any) => {

    const { author = null, tags = [], ...others } = props;
    const postDate = new Date(props.updated_on);
    const history = useHistory();

    const more = (e: any) => {
        history.push(`/observatorio/${props.id}`)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <img width="100%" height="100%" src={props.featured_image} alt="Imagem do post" />
            </Grid>
            <Grid item container xs>
                <Grid item xs={12}>
                    <Box fontSize="h5.fontSize" color={theme.palette.primary.main}
                        fontWeight="bolder">
                        {props.title}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display="-webkit-flex">
                        {tags.map((item: any) =>
                            <Box border={2} borderColor={grey[500]} color={grey[500]}
                                p={0.5} mr={1} key={item.id}>
                                {item.name}
                            </Box>
                        )}
                    </Box>
                </Grid>
                <Grid item>
                    <Box maxHeight="100px" textOverflow="ellipsis" overflow="hidden" my={2} pr={2}>
                        {props.excerpt}
                    </Box>
                    <Button size="small" color="primary" onClick={more}>Continuar lendo...</Button>
                </Grid>
                <Box display="-webkit-flex" alignItems="center" bottom={0} position="relative">
                    <Avatar src={author?.avatar} />&nbsp;&nbsp;
                    <Box color={theme.palette.primary.main} fontWeight="bolder" fontSize="1.1rem">
                        {author?.name}
                    </Box>&nbsp;&nbsp;
                    <Divider orientation="vertical" variant="middle" />&nbsp;&nbsp;
                    <Typography>{dateFns.format(postDate, 'dd-MM-yyyy H:mm')}</Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

export default PostCard;