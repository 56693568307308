import { Box, Container, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { GeoAltFill } from 'react-bootstrap-icons';
import { useHistory, useParams } from 'react-router';
import ContactProfessional from '../../components/ContacProfessional';
import IsFav from '../../components/IsFav';
import ShareButton from '../../components/ShareButton';
import SkillItens from '../../components/SkillItens';
import { Expertise, KnownledgeField, Professional } from '../../models';
import { getProfessional } from '../../services/CatalogoService';
import * as dateFns from 'date-fns';
import ProfileBar from './ProfileBar';
import useStyles from './style';

const ProfessionalDetail = () => {
    const classes = useStyles();
    const [data, setData] = useState<Professional | null>(null);
    const [areasAtuacao, setAreasAtuacao] = useState<string[]>([]);
    const [areasEspecificas, setAreasEspecificas] = useState<string[]>([]);
    const [specialities, setSpecialities] = useState<string[]>([]);
    const [languages, setLanguages] = useState<string[]>([]);
    const [age, setAge] = useState(0);
    const params = useParams<{ id?: string }>();
    const history = useHistory();

    useEffect(() => {
        if (params.id) {
            getProfessional(params.id,
                (data: Professional) => {
                    setData({ ...data });
                },
                (e: any) => {
                    console.error(e);
                    history.push('/error')
                })
        }
    }, [params, history]);

    const expertisesCallback = useCallback(() => {
        const subjectAreas = data?.details?.subject_areas;
        if (subjectAreas) {
            const expertises: Expertise[] = [];
            subjectAreas.forEach(item => {
                if (item.expertises) {
                    expertises.push(...item.expertises);
                }
            })
            setAreasEspecificas([...expertises?.map(item => item.name)])
        }
    }, [data?.details?.subject_areas]);

    useEffect(() => {
        if (data) {
            if (data.details?.subject_areas) {
                const subjectAreas: KnownledgeField[] = [...data.details.subject_areas];
                setAreasAtuacao([...subjectAreas.map(item => item.name)]);
            }
            if (data.details?.languages) {
                const langs = [...data.details.languages.map(item => item.name)];
                setLanguages(langs);
            }
            if (data.details?.speciality) {
                const specs = [...data.details.speciality.map((item => item.name))];
                setSpecialities(specs);
            }
            if (data.date_of_birth) {
                const dt = new Date(data.date_of_birth);
                const age = dateFns.differenceInYears(new Date(), dt);
                setAge(age);
            }
        }

    }, [data])

    useEffect(() => {
        expertisesCallback();
    }, [expertisesCallback])

    return (
        <Container>
            {data !== null && (
                <Grid container>
                    <Grid item sm={12} md={4} lg={3}>
                        <ProfileBar data={data} />
                    </Grid>
                    <Grid item xs className={classes.profile}>
                        <Grid container>
                            <Grid item xs>
                                <Box display="-webkit-flex" alignItems="start">
                                    <Box mr={4}>
                                        <Typography variant="h6">
                                            {data?.person_type === 'NP' ?
                                                'Profissional' : 'Empresa'}
                                        </Typography>
                                        <Typography variant="h4" display="block">
                                            {data?.details?.corporate_name}
                                        </Typography>
                                        {data.person_type === 'NP' && (<Typography variant="h5" display="block">
                                            {age} anos
                                        </Typography>)}
                                    </Box>
                                </Box>
                            </Grid>
                            {(<Grid item xs={2}>
                                <Box display="-webkit-flex" justifyContent="right">
                                    <IsFav professionalId={data.id} />
                                    <ShareButton url={window.location.href} title={data.details?.corporate_name || ''}/>
                                </Box>
                            </Grid>)}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs>
                                    <Box display="-webkit-flex">
                                        <GeoAltFill size={30} />&nbsp;&nbsp;
                                        <Typography variant="h6">
                                            {data?.details?.city ?
                                                data?.details?.city.name.toUpperCase() :
                                                'Município não informado'}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <ContactProfessional recipient={data?.id} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                            <Typography variant="h6">Biografia</Typography>
                            <Typography paragraph align="justify" className={classes.resume}>
                                {data?.details?.professional_resume ?
                                    data?.details?.professional_resume :
                                    'Não declarado'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SkillItens title="Áreas de Atuação" skills={areasAtuacao} />
                            <SkillItens title="Áreas Específicas" skills={areasEspecificas} />
                            <SkillItens title="Especialidades" skills={specialities} />
                            <SkillItens title="Idiomas" skills={languages} />
                        </Grid>
                    </Grid>
                </Grid>)}
        </Container>
    )
}

export default ProfessionalDetail;