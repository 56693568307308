import { Avatar, Box, Breadcrumbs, Container, Divider, Typography } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';
import useStyles from './style';
import * as dateFns from 'date-fns';
import theme from '../../theme';
import { getPost } from '../../services/BlogServices';
import CustomBreadcrumb, { Trail } from '../../components/CustomBreadcrumb';

const Post: FC = (props: any) => {
    const [postDate, setPostDate] = useState('');
    const [pageContent, setPageContent] = useState<any>({});
    const [author, setAuthor] = useState<any | null>(null);
    const classes = useStyles();
    const params = useParams<{ id: string }>()


    useEffect(() => {
        getPost(Number(params.id), (response: any) => {
            setPageContent(response.data);
            const dt = new Date(response.data.updated_on);
            setPostDate(dateFns.format(dt, "dd/MM/yyyy à's' HH:mm"));
            setAuthor(response.data.author);
        },
            (err: any) => {
                console.error(err)
            })
    }, [params.id]);

    const trail: Trail[] = [
        { label: 'Início', link: '/' },
        { label: 'Observatorio', link: '/observatorio' },
        { label: pageContent.title }
    ];

    return (
        <Container>
            <CustomBreadcrumb trail={trail} />
            <Box fontSize="h3.fontSize" color={theme.palette.primary.main}
                fontWeight="bolder">
                {pageContent.title}
            </Box>
            <Box display="-webkit-flex" alignItems="center">
                Por:&nbsp;&nbsp;<b>{author?.name}</b>&nbsp;&nbsp;-&nbsp;&nbsp;
                {postDate}
            </Box>
            <Divider style={{margin: '8px 0'}}/>
            <Box dangerouslySetInnerHTML={{ __html: pageContent.content }} />
            <Divider style={{margin: '8px 0'}}/>
            <Box display="-webkit-flex" alignItems="center" my={4} justifyContent="space-between">
                <Box display="-webkit-flex" alignItems="center">
                    <Avatar src={author?.avatar} />&nbsp;&nbsp;
                    <Box color={theme.palette.primary.main} fontWeight="bolder" fontSize="1.1rem">
                        {author?.name}
                    </Box>
                </Box>
                <Typography>{postDate}</Typography>
            </Box>
        </Container>
    )
}

export default Post;