import { FilterOption } from "../components/FilterSelect";
import { Expertise, Professional, State } from "../models";
import api from "./api";

export interface ProfessionalSearchParam {
    person_type?: 'NP' | 'LP';
    kf?: number | Array<number>;
    expertise?: number | Array<number>;
    speciality?: number | Array<number>;
    locality?: number | Array<number>;
    gender?: number | Array<number>;
    sexual_orientation?: number | Array<number>;
    skin_tone?: number | Array<number>;
    lang?: number | Array<number>;
    search?: string;
    ordering?: string;
}

/** Carrega a lista de profissionais */
export function searchProfessionals(
    page: number, params: ProfessionalSearchParam | null,
    success: any, failed: any,
    itemsPerPage: number = 15
) {
    const fetch = async () => {
        let searchQuery: string = '';
        const entries = params ? Object.entries(params) : [];
        entries.forEach(([key, value]) => {
            let values = value;
            if (typeof (value) === 'object') {
                values = value.join(',');
            }
            searchQuery += values ? `&${key}=${values}` : '';
        });
        return await api.get(
            `/professionals/?page=${page}&page_size=${itemsPerPage}${searchQuery}`
        );
    };
    const response = fetch();
    return response.then(success).catch((e) => failed(e));
}

/**
 * Carrega os dados do profissional
 * @param id - id do profissional
 * @param success - callback de sucesso
 * @param failed  - callback de erro
 */
export function getProfessional(id: number | string, success: any, failed: any) {
    const fetch = async () => {
        return await api.get(`/professional/${id}`);
    };

    const response = fetch();
    return response.then(
        (response) => {
            const data: Professional = response.data;
            success(data);
        }
    ).catch((e) => failed(e));
}

/** Carrega a lista de cidades*/
export function fetchCities(success: any, failed: any) {
    const fetch = async () => {
        return await api.get('cities/');
    };
    const response = fetch();
    return response.then(
        (response) => {
            const data: Array<FilterOption> = [];
            response.data.forEach((item: any) => {
                data.push({
                    label: `${item.name} - ${item.state.acronym}`,
                    value: item.id
                })
            })
            success(data)
        }
    ).catch((e) => failed(e));
}

/**
 * Carrega a lista de cidades por estado
 * @param uf uf em caixa alta
 * @param success callback de sucesso
 * @param failed callback de error
 * @returns
 */
export function fetchCitiesByState(uf: string, success: any, failed: any) {
    fetchItem(`cities/?state=${uf}`, success, failed);
}

/**
 * Carrega a lista de estados
 * @param success callback de sucesso
 * @param failed callback de error
 * @returns
 */
export function fetchStates(success: any, failed: any) {
    const fetch = async () => {
        return await api.get('states/');
    };
    const response = fetch();
    return response.then(
        (response) => {
            const data: Array<State> = response.data;
            success(data)
        }
    ).catch((e) => failed(e));
}

/**
 * Carrega o retorno da requisição em um array de FilterOptions
 * @param endpoint endpoit
 * @param success callback de sucesso
 * @param failed callback de error
 */
function fetchItem(endpoint: string, success: any, failed: any) {
    const fetch = async () => {
        return await api.get(endpoint);
    };
    const response = fetch();
    return response.then(
        (response) => {
            const data: Array<FilterOption> = [];
            const result = response.data.results ? response.data.results : response.data;
            result.forEach((item: any) => {
                data.push({
                    label: item.name,
                    value: item.id
                })
            })
            success(data)
        }
    ).catch((e) => failed(e));
}

/**
 * Carrega a lista de expertises
 * @param success callback de sucesso
 * @param failed callback de error
 */
export function fetchExpertises2(success: (data: any) => void, failed: (error: any) => void) {
    const fetch = async () => {
        return await api.get('expertises/');
    };

    const response = fetch();
    return response.then(
        (response) => {
            const data: Array<Expertise> = response.data;
            success(data);
        }
    ).catch((e) => failed(e));
}

/** Carrega a lista de tipos de serviços*/
export function fetchExpertises(success: any, failed: any) {
    fetchItem('expertises/', success, failed);
}

/** Carrega a lista de Áreas de atuação*/
export function fetchKnowledgeFields(success: any, failed: any) {
    fetchItem('knowledge-fields/', success, failed);
}

/** Carrega a lista Especialidades*/
export function fetchSpecialities(success: any, failed: any) {
    fetchItem('specialities/', success, failed);
}

/**
 * Carrega a lista de orientações sexuais
 * @param success callback de sucesso
 * @param failed callback de error
 */
export function fetchSexualOrientation(success: (data: any) => void, failed: (error: any) => void) {
    fetchItem('sexual-orientations/', success, failed);
}

/**
 * Carrega a lista de cor da pele
 * @param success callback de sucesso
 * @param failed callback de error
 */
export function fetchSkinTones(success: (data: any) => void, failed: (error: any) => void) {
    fetchItem('skin-tones/', success, failed);
}

/**
 * Carrega a lista de grau de instrução
 * @param success callback de sucesso
 * @param failed callback de error
 */
export function fetchEducationLevels(success: (data: any) => void, failed: (error: any) => void) {
    fetchItem('education-levels/', success, failed);
}

/**
 * Carrega a lista de identidades de gênero
 * @param success callback de sucesso
 * @param failed callback de error
 */
export function fetchGenderIdentities(success: (data: any) => void, failed: (error: any) => void) {
    fetchItem('gender-identities/', success, failed);
}

/**
 * Carrega a lista porte da empresa
 * @param success callback de sucesso
 * @param failed callback de error
 */
export function fetchBusinessSize(success: (data: any) => void, failed: (error: any) => void) {
    fetchItem('business-sizes/', success, failed);
}

/**
 * Carrega a lista de idiomas para o profissional
 * @param success callback de sucesso
 * @param failed callback de error
 */
export function fetchLanguages(success: (data: any) => void, failed: (error: any) => void) {
    fetchItem('languages/', success, failed);
}

/**
 * Carrega a lista de idiomas para o profissional
 * @param success callback de sucesso
 * @param failed callback de error
 */
export function fetchSocialNetwork(success: (data: any) => void, failed: (error: any) => void) {
    fetchItem('medias/', success, failed);
}

/**
 * Salva os detalhes de um profissional
 * @param userId id do profissional
 * @param success callback de sucesso
 * @param failed callback de error
 */
export function updateProfessional(professional: Professional, success: (data: any) => void, failed: (error: any) => void) {
    const expertises = professional.details?.expertises.map(item => item.id);
    if (expertises?.length === 0 && professional.details?.subject_areas) {
        professional.details.subject_areas.forEach(item => {
            if (item.expertises) {
                expertises.push(...item.expertises.map(item => item.id));
            }
        })
    }
    const speciality = professional.details?.speciality.map(item => item.id);
    const languages = professional.details?.languages.map(item => item.id);
    let socialNetworks: any[] = []
    if (professional.details?.social_networks) {
        professional.details?.social_networks.forEach(item => {
            socialNetworks.push({ social_media: item.social_media_id, username: item.username })
        });
    }
    const data = {
        ...professional,
        gender: professional.gender?.id,
        details: {
            ...professional.details,
            business_size: professional.details?.business_size?.id || null,
            expertises: expertises,
            speciality: speciality,
            languages: languages,
            social_networks: socialNetworks,
            city: professional.details?.city.id !== 0 ? professional.details?.city.id : undefined,
            education_level: professional.details?.education_level?.id !== 0 ? professional.details?.education_level?.id : undefined,
            sexual_orientation: professional.details?.sexual_orientation?.id,
            skin_tone: professional.details?.skin_tone?.id,
        },
    };

    const save = async () => {
        return await api.patch(`/professional/${data.id}/edit/`, data);
    }

    const response = save();
    return response.then((response) => success(response)).catch((e) => failed(e));
}

/**
 * Altera o avatar do profissional
 * @param id id do usuario
 * @param avatar data
 * @param success callback de sucesso
 * @param failed callback de error
 */
export function changeAvatar(id: number | string, avatar: any, success: (data: any) => void, failed: (error: any) => void) {
    const formData = new FormData();
    formData.append('avatar', avatar.file);

    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const upload = async () => {
        return await api.put(`/professional/${id}/avatar/`, formData, config);
    }

    const response = upload();
    return response.then((response) => success(response)).catch((e) => failed(e));
}

/**
 * Altera a senha do usuário
 * @param dados dados do usuário
 * @param success callback de sucesso
 * @param failed callback de error
 * @returns
 */
export function changePassword(data: {
    id: number, password: string, password2: string, old_password: string
},
    success: any, failed: any) {
    const update = async () => {
        return await api.put(`/users/${data.id}/change_password/`, data);
    }

    const response = update();
    return response.then((response) => success(response)).catch((e) => failed(e));
}

/**
 * Altera o email do usuário
 * @param data dados do usuário
 * @param success callback de sucesso
 * @param failed callback de error
 * @returns
 */
export function changeEmail(data: { id: number, email: string },
    success: any, failed: any) {
    const update = async () => {
        return await api.patch(`/professional/${data.id}/edit/`, data);
    }

    const response = update();
    return response.then((response) => success(response)).catch((e) => failed(e));
}

/**
 * Altera o email do usuário
 * @param data dados de conexão
 * @param success callback de sucesso
 * @param failed callback de error
 */
export function contactProfessional(data: { sender: number, recipient: number },
    success: any, failed: any) {
    const contact = async () => {
        return await api.post(`/professionals/contact/`, data);
    }

    const response = contact();
    return response.then((response) => success(response)).catch((e) => failed(e));
}

/**
 * Altera o email do usuário
 * @param page número da pagina
 * @param success callback de sucesso
 * @param failed callback de error
 * @param itemsPerPage número de itens por pagina
 */
export function getContacts(page: number, success: any, failed: any, itemsPerPage: number = 15) {
    const contacts = async () => {
        return await api.get(`/professionals/contact/?page=${page}&page_size=${itemsPerPage}`);
    }

    const response = contacts();
    return response.then((response) => success(response.data)).catch((e) => failed(e));
}

/**
 * Carrega os dados de um contratante
 * @param id - id do contratante
 * @param success - callback de sucesso
 * @param failed  - callback de erro
 */
export function getHirer(id: number | string, success: any, failed: any) {
    const fetch = async () => {
        return await api.get(`/hirers/${id}/`);
    };

    const response = fetch();
    return response.then(
        (response) => {
            success(response.data);
        }
    ).catch((e) => failed(e));
}
/**
 * atualiza os dados de um contratante
 * @param data - dados do contratante
 * @param success - callback de sucesso
 * @param failed  - callback de erro
 */
export function updateHirer(data: any, success: any, failed: any) {
    const update = async () => {
        return await api.patch(`/hirers/${data.id}/`, data);
    };

    const response = update();
    return response.then(
        (response) => {
            success(response.data);
        }
    ).catch((e) => failed(e));
}

/**
 * Adiciona um profissional ao favoritos do usuario autenticado
 * @param professionalId - id do profissional a ser adicionado como favorito
 * @param success - callback de sucesso
 * @param failed  - callback de erro
 */
export function addFavorite(professionalId: number, success: any, failed: any) {
    const add = async () => {
        return await api.post('/favorites/', {id: professionalId});
    }
    const response = add();
    return response.then(
        (response) => {
            success(response.data);
        }
    ).catch((e) => failed(e));
}

export const userFavList = () => {
    const list = localStorage.getItem('TA-Favorites');
    return list ? Array.from<any>(JSON.parse(list)) : [];
};

/**
 * Retorna a lista de profissionais favoritos do usuario autenticado
 * @param success - callback de sucesso
 * @param failed  - callback de erro
 */
export function fetchFavorites(success: any, failed: any) {
    const fetch = async () => {
        return await api.get('/favorites/');
    };

    const response = fetch();
    return response.then(
        (response) => {
            localStorage.setItem('TA-Favorites', JSON.stringify(response.data.results));
            success(response.data);
        }
    ).catch((e) => failed(e));
}

/**
 * Atualiza a lista de favoritos do usuário autenticado
 */
export function refreshFavorites(onEnd: any) {
    
    fetchFavorites(onEnd, onEnd)
}

/**
 * Remove um profissional dos favoritos do usuario autenticado
 * @param professionalId - id do profissional a ser adicionado como favorito
 * @param success - callback de sucesso
 * @param failed  - callback de erro
 */
export function removeFavorite(idProfissional: number, success: any, failed: any) {
    const remove = async () => {
        return await api.delete(`/favorites/${idProfissional}`);
    };

    const response = remove();
    return response.then(
        (response) => {
            success(response.data);
        }
    ).catch((e) => failed(e));
}
