import { createStyles, makeStyles, Theme } from "@material-ui/core";
import bgImage from "../../assets/bg-main.jpg";
import bgWelcome from "../../assets/bg.png";
import bgGraphic from "../../assets/graphic.png"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        welcomeSection: {
            fontFamily: 'Raleway',
            backgroundSize: 'auto, cover',
            width: '100%',
            height: '600px',
            [theme.breakpoints.down('sm')]: {
                background: `url(${bgWelcome}) left top no-repeat`,
                '& #welcome-text > div': {
                    fontSize: '2.5rem'
                }
            },
            [theme.breakpoints.up('sm')]: {
                background: `url(${bgGraphic}) right bottom no-repeat, url(${bgWelcome}) left top`,
                '& #welcome-text > div': {
                    fontSize: '3.5rem'
                }
            }
        },
        searchSection: {
            background: `url(${bgImage}) center no-repeat`,
            backgroundSize: 'cover',
            backgroundPositionY: '65%',
            width: '100%',
            height: '600px',
            marginBottom: '-10em', //Pra compensar o espaçamento presente no footer (marginTop='10em').
            '& input': {
                backgroundColor: 'white',
                '& ::placeholder': {
                    color: theme.palette.primary.main,
                },
                '& :focus, :hover': {
                    backgroundColor: 'white',
                }
            },
            '& select': {
                padding: '10px'
            },
            '& button': {
                padding: '10px'
            },
            '& .MuiAutocomplete-endAdornment': {
                top: 'unset',
            },
        },
    })
);

export default useStyles;