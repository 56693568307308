import { Backdrop, Box, CircularProgress, Container, Grid, Paper } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Professional } from '../../models';
import { getUserId } from '../../services/auth';
import { getHirer, getProfessional } from '../../services/CatalogoService';
import AccountSettings from './AccountSettings';
import Calls from './Calls';
import Favorites from './Favorites';
import MenuBar from './MenuBar';
import EditProfile from './ProfileSection/EditProfile';
import useStyles from './style';


const Profile: FC = (props: any) => {
    const classes = useStyles();
    const [data, setData] = useState<Professional | null>(null);
    const [menuOption, setMenuOption] = useState<any>('');
    const [isHirer, setIsHirer] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const params = useMemo(
        getParams, []);

    /** Carrega a filtro de acordo com os parametros passados */
    function getParams() {
        return { id: getUserId() }
    }


    useEffect(() => {
        if (params.id) {
            setIsLoading(true);
            getHirer(params.id,
                (data: Professional) => {
                    setData({ ...data });
                    setIsLoading(false);
                },
                (e: any) => {
                    setIsHirer(false);
                    console.warn(e.message);
                    setIsLoading(false);
                })
        }
    }, [params]);

    const refreshProfessional = useCallback((id: number) => {
        if (!isHirer) {
            setIsLoading(true);
            getProfessional(id,
                (data: Professional) => {
                    setData({ ...data });
                    setIsLoading(false);
                },
                (e: any) => {
                    console.error(e);
                    setIsLoading(false);
                    history.push('/error');
                })
        }
    }, [history, isHirer]);

    useEffect(() => {
        if (params.id && !data) {
            refreshProfessional(params.id);
        }
    }, [refreshProfessional, data, params]);

    useEffect(() => {
        if (data && menuOption === '') {
            if (isHirer) {
                setMenuOption(<AccountSettings values={data} />)
            } else {
                setMenuOption(<EditProfile values={data} />)
            }
        }
    }, [data, menuOption, isHirer]);

    const handleChangeMenu = (option: string) => {
        let element: any = '';
        if (data) {
            switch (option) {
                case 'chamados':
                    element = <Calls values={data} />
                    break;
                case 'favoritos':
                    element = <Favorites values={data} />
                    break;
                case 'conta':
                    element = <AccountSettings values={data} />
                    break;
                default:
                    element = <EditProfile values={data} />
                    break;
            }
        }
        setMenuOption(element);
        refreshProfessional(params.id);
    }


    return (
        <Box>
            <Container>
                <Grid container>
                    <Grid item sm={12} md={3}>
                        <MenuBar isHirer={isHirer} onChange={handleChangeMenu} />
                    </Grid>
                    <Grid item xs>
                        <Paper className={classes.content}>
                            {menuOption}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <Backdrop open={isLoading} className={classes.backdrop}>
                <CircularProgress color="primary"/>
            </Backdrop>
        </Box>
    )
}

export default Profile;