import React, { ChangeEvent } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { createStyles, makeStyles } from "@material-ui/core";

export interface PaginationProps {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    handlePagination: (event: ChangeEvent<unknown>, value: number) => void;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            "& > *": {
                marginTop: theme.spacing(2),
            },
        },
    })
);

const CPagination: React.FC<PaginationProps> = ({
    currentPage,
    itemsPerPage,
    totalItems,
    handlePagination,
}) => {
    const classes = useStyles();

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
        <div className={classes.root}>
            <Pagination
                shape="rounded"
                count={totalPages}
                defaultPage={1}
                onChange={handlePagination}
                showFirstButton
                showLastButton
                color="secondary"
            />
        </div>
    );
};

export default CPagination;
