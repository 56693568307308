import { Box, Breadcrumbs, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export interface Trail {
    link?: string;
    label: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    breadcrumbs: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.light,
        color: 'white',
        marginLeft: -theme.spacing(3),
        marginBottom: theme.spacing(3),
        fontSize: 'small',
        width: 'fit-content',
        borderRadius: '5px',
        '& a': {
            textDecoration: 'none',
            color: 'white'
        },
        minHeight: '100%',
        '& ol': {
            whiteSpace: 'nowrap',
            flexFlow: 'nowrap'
        },
    },
}))

const CustomBreadcrumb: FC<{ trail: Array<Trail> }> = ({ trail }) => {

    const classes = useStyles();

    return (
        <Breadcrumbs className={classes.breadcrumbs} separator="›" aria-label="breadcrumb" >
            {trail.map(item => {
                if (item.link) {
                    return <Link key={item.label} to={item.link}>{item.label}</Link>
                } else {
                    return <Box key={item.label}>{item.label}</Box>
                }
            })}
        </Breadcrumbs>
    )
}

export default CustomBreadcrumb;